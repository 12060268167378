.team-card-one {
	&__image {
		position: relative;

		img {
			width: 100%;
		}

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			position: absolute;
			top: 0;
			right: 0;
			background-image: url(../images/shapes/team-shine-1-1.png);
			background-size: cover;
		}
	}

	&__social {
		position: absolute;
		bottom: 20px;
		right: 20px;
		z-index: 10;

		&__links {
			@include reset-ul;
			background-color: #fff;
			margin-bottom: 20px;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 10px;
			transform: scale(1, 0);
			transition: transform 500ms ease;
			transform-origin: bottom center;

			li {
				a {
					color: $cretech-black;
					font-size: 16px;
				}
			}
		}

		&:hover &__links {
			transform: scale(1, 1);
		}

		&__icon {
			cursor: pointer;
			width: 50px;
			height: 50px;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 500ms ease;

			i {
				color: $cretech-primary;
				font-size: 24px;
			}
		}

		&:hover &__icon {
			background-color: $cretech-primary;

			i {
				color: #fff;
			}
		}
	}

	&__content {
		padding-left: 26px;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
		transition: all 500ms ease-out;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 20px;
			width: 6px;
			height: 44px;
			background-color: $cretech-secondary;
		}


	}

	&:hover &__content {
		background-color: $cretech-secondary;
	}

	&__title {
		margin: 0;
		color: $cretech-black;
		font-size: 22px;
		font-weight: bold;
		letter-spacing: -0.05em;
		margin-top: -5px;

		a {
			background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 1px no-repeat;
			color: inherit;
			transition: all 500ms ease;

			&:hover {
				color: #fff;
				background-size: 100% 1px;
			}
		}
	}

	&:hover &__title a {
		color: #fff;
	}

	&__designation {
		letter-spacing: -0.05em;
		margin: 0;
		color: $cretech-primary;
		line-height: 1;
		margin-top: 2px;
		transition: all 500ms ease;
	}

	&:hover &__designation {
		color: #fff;
	}
}