.project-card-one {
	position: relative;
	overflow: hidden;

	&__image {
		img {
			width: 100%;
		}
	}

	&__content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 30px;
		padding-right: 50px;

		&__inner {
			@include cretech-gradient;
			position: relative;
			width: 100%;
			padding: 30px;
			transform: scale(1, 0);
			perspective: 400px;
			visibility: hidden;
			transition: transform 500ms ease-in-out, visibility 500ms ease-in-out;
			transform-origin: bottom center;
		}
	}

	&:hover &__content__inner {
		transform: scale(1, 1);
		visibility: visible;
		transform-origin: top center;
	}

	&__text {
		margin: 0;
		color: #e5d4ff;
		font-size: 14px;
		font-weight: 500;
		margin-top: -10px;
	}

	&__title {
		margin: 0;
		font-size: 24px;
		line-height: 1.25em;
		color: #fff;
		font-weight: 700;
		letter-spacing: -0.025em;
		max-width: 165px;
		margin-top: -5px;

		a {
			background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 2px no-repeat;
			color: inherit;
			transition: all 500ms ease;

			&:hover {
				color: #fff;
				background-size: 100% 2px;
			}
		}
	}

	&__more {
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translate(50%, -50%);
		width: 40px;
		height: 40px;
		background-color: #000000;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		transition: all 500ms ease;
		max-width: 160px;

		&:hover {
			background-color: #fff;
			color: $cretech-black;
		}
	}
}


.project-nav {
	&__inner {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid $cretech-border;
		border-bottom: 1px solid $cretech-border;
		justify-content: space-between;
		padding-top: 30px;
		padding-bottom: 30px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			color: $cretech-black;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: .1em;

			&:hover {
				color: $cretech-primary;
			}

			i {
				margin-right: 10px;
			}

			&:nth-child(2) {
				i {
					margin-right: 0;
					margin-left: 10px;
				}
			}
		}
	}
}

.project-details {
	&__title {
		margin: 0;
		color: $cretech-black;
		font-size: 30px;
		font-weight: bold;
		letter-spacing: -0.05em;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			font-size: 35px;
		}

		@media (min-width: 992px) {
			font-size: 40px;
		}

	}

	&__content {
		p {
			margin: 0;

			+p {
				margin-top: 20px;

				@media (min-width: 992px) {
					margin-top: 30px;
				}

				@media (min-width: 1200px) {
					margin-top: 45px;
				}
			}
		}
	}

	&__list {
		@include reset-ul;
		margin-bottom: -10px;

		@media (min-width: 992px) {
			margin-top: 20px;
		}

		@media (min-width: 1200px) {
			margin-top: 38px;
		}

		li {
			position: relative;
			color: $cretech-black;
			font-size: 18px;
			line-height: 2.11em;
			padding-left: 26px;

			>i {
				color: $cretech-primary;
				font-size: 16px;
				position: absolute;
				top: 12px;
				left: 0;
			}
		}
	}

	&__image {
		img {
			max-width: 100%;
		}
	}

	&__info {
		@include cretech-gradient;
		margin-top: 40px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 10;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-bottom: 30px;
		flex-direction: column;

		@media (min-width: 768px) {
			flex-direction: row;
			padding-top: 20px;
			padding-bottom: 14px;
			align-items: center;
			justify-content: space-between;
		}

		@media (min-width: 992px) {
			margin-left: 30px;
			margin-right: 30px;
			padding-left: 30px;
			padding-right: 30px;
			margin-top: -40px;
			padding-top: 49px;
			padding-bottom: 43px;
		}

		@media (min-width: 992px) {
			margin-left: 40px;
			margin-right: 40px;
			padding-left: 60px;
			padding-right: 60px;
		}

		&__item+&__item {
			margin-top: 10px;

			@media (min-width: 768px) {
				margin-top: 0;
			}
		}


		&__title {
			margin: 0;
			font-size: 16px;
			font-weight: 500;
			color: #fff;
			margin-bottom: 5px;
		}

		&__text {
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			color: #fff;

			a {
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: $cretech-black;
				}
			}
		}

		&__social {
			display: flex;
			align-items: center;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				background-color: rgba(255, 255, 255, .2);
				border-radius: 50%;
				font-size: 14px;
				transition: all 500ms ease;
				color: #fff;

				&:hover {
					color: #fff;
					background-color: $cretech-black;
				}

				+a {
					margin-left: 10px;
				}
			}
		}
	}
}


.project-one {
	&__top {
		margin-bottom: 50px;

		@media (min-width: 992px) {
			margin-bottom: 60px;
		}


		.section-title {
			padding-bottom: 30px;

			@media (min-width: 992px) {
				padding-bottom: 0;
			}
		}

		&__text {
			margin: 0;
			margin-bottom: 30px;

			@media (min-width: 992px) {
				text-align: right;
				margin-bottom: 0;
				margin-top: 11px;
			}
		}

		&__content {
			@media (min-width: 992px) {
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-end;
			}
		}

		&__btn {
			background-color: $cretech-gray;
			color: $cretech-black;
		}
	}

	&__carousel {
		&.thm-owl__carousel--with-shadow .owl-item.active+.owl-item {
			@media (min-width: 1200px) {
				opacity: 1;
				visibility: visible;
			}
		}

		.owl-nav {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 40px;

			@media (min-width: 992px) {
				margin-top: 60px;
			}

			@media (min-width: 1200px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				position: absolute;
				bottom: 0;
				right: calc(100% + 20px);
				margin-top: 0;
			}

			[class*=owl-] {
				margin: 0;
				width: 51px;
				height: 51px;
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
				transition: all 500ms ease;
				border-radius: 50%;

				+[class*=owl-] {
					margin-left: 10px;

					@media (min-width: 1200px) {
						margin-left: 0;
						margin-top: 10px;
					}
				}

				&:hover {
					background-color: $cretech-primary;

					span {
						color: #fff;
					}
				}

				span {
					color: $cretech-black;
					transition: all 500ms ease;
					font-size: 20px;
				}
			}
		}
	}
}

.project-two {
	.container-fluid {
		width: 100%;
		max-width: 1630px;

		@media (min-width: 992px) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.project-three {
	.container-fluid {
		width: 100%;
		max-width: 1530px;

		@media (min-width: 992px) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.project-card-two {
	background-color: $cretech-black;
	min-height: 400px;
	position: relative;
	width: 100%;

	@media (min-width: 992px) {
		width: 290px;
	}

	&--large {
		@media (min-width: 992px) {
			width: 570px;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
	}

	&__content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		padding: 30px;
		overflow: hidden;
	}

	&__category {
		background-color: $cretech-primary;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: .1em;
		line-height: 1em;
		color: #fff;
		padding: 16px 25px;
		opacity: 0;
		visibility: hidden;
		transition: 500ms ease;
		transform: translateY(60px);
	}

	&:hover &__category {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	&__title {
		margin: 0;
		background-color: #fff;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
		font-size: 20px;
		font-weight: bold;
		letter-spacing: -0.05em;
		color: $cretech-black;
		padding: 18px 44px 18px 25px;
		line-height: 1.2em;
		opacity: 0;
		visibility: hidden;
		transform: translateY(50px);
		transition: 500ms ease;

		a {
			background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 2px no-repeat;
			color: inherit;
			transition: all 500ms ease;

			&:hover {
				color: $cretech-primary;
				background-size: 100% 2px;
			}
		}
	}

	&:hover &__title {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}