@keyframes bgSlide {
	0% {
		background-position: 0 0;
	}

	20% {
		background-position: -100px 0;
	}

	40% {
		background-position: -200px 0;
	}

	60% {
		background-position: -150px 0;
	}

	80% {
		background-position: -100px 0;
	}

	100% {
		background-position: 0px 0;
	}
}


@keyframes videoBtnAnim {
	0% {
		transform: scale(0.9);
	}

	25% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	75% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.9);
	}
}

.zoom-fade {
	animation-name: zoom-fade;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes zoom-fade {
	0% {
		transform: scale(0.9);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.9);
	}
}

@keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}