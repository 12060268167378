.topbar {
	background-color: $cretech-black;
	display: none;

	@media (min-width: 835px) {
		display: block;
	}

	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 6px;
		padding-bottom: 6px;

		@media (min-width: 768px) {
			padding-left: 30px;
			padding-right: 30px;
		}

		@media (min-width: 992px) {
			padding-left: 60px;
			padding-right: 60px;
		}
	}

	&__text {
		margin: 0;
		font-size: 13px;
		font-weight: 500;
		color: $cretech-text-light;
	}

	&__info {
		@include reset-ul;
		display: flex;
		align-items: center;
		margin-left: auto;

		li {
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: 500;
			color: $cretech-text-light;

			+li {
				margin-left: 20px;

				@media (min-width: 1200px) {
					margin-left: 30px;
				}
			}

			i {
				color: #ab32f2;
				margin-right: 10px;
			}

			a {
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	&__social {
		@include reset-ul;
		align-items: center;
		margin-left: 30px;
		display: none;

		@media (min-width: 1050px) {
			display: flex;
		}

		@media (min-width: 1200px) {
			margin-left: 60px;
		}

		li {
			+li {
				margin-left: 27px;
			}

			a {
				color: #fff;
				font-size: 14px;
				transition: all 500ms ease;

				&:hover {
					color: $cretech-primary;
				}
			}
		}
	}
}

.topbar-two {
	display: none;

	@media (min-width: 1200px) {
		display: block;
	}

	&__inner {
		background-color: #fff;
		padding: 20px 40px;
		display: flex;
		align-items: center;

	}

	&__list {
		@include reset-ul;
		margin-left: auto;
		display: flex;
		align-items: center;
		margin-right: 30px;

		&__item {
			display: flex;
			align-items: center;
			color: $cretech-text;
			letter-spacing: -0.05em;
			line-height: 1;


			a {
				background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 2px no-repeat;
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: $cretech-primary;
					background-size: 100% 2px;
				}
			}

			span {
				display: block;
				color: $cretech-black;
				font-weight: bold;
				margin-bottom: 5px;
			}
		}

		&__item+&__item {
			margin-left: 40px;
		}


		&__icon {
			width: 40px;
			height: 40px;
			background-color: $cretech-secondary;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 16px;
			color: #fff;
			margin-right: 20px;
			transition: all 500ms ease-in-out;

			&:hover {
				background-color: $cretech-black;
				color: #fff;
			}

			i.map-marker {
				font-size: 13px;
			}
		}
	}

	&__btns {
		display: flex;
	}

	&__btn {
		font-size: 12px;
		padding: 9.5px 39px;
	}
}

.main-menu {
	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;

		@media (min-width: 768px) {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 30px;
			padding-right: 0;
		}

		@media (min-width: 992px) {
			padding-left: 60px;
			padding-right: 0px;
		}
	}

	&__list {
		display: none;
		@include reset-ul;

		@media (min-width: 1200px) {
			display: flex;
			align-items: center;
			margin-left: auto;
		}

		>li {
			position: relative;

			+li {
				margin-left: 40px;

				@media (min-width: 1270px) {
					margin-left: 58px;
				}
			}


			>a {
				text-transform: uppercase;
				color: $cretech-text;
				font-weight: 500;
				transition: all 500ms ease;
				display: block;
				padding: 29.5px 0;
			}

			&:hover,
			&.current {
				>a {
					text-shadow: 0 0 1px currentColor;
					color: $cretech-black;
				}
			}

			>ul {
				left: 0;
				top: 100%;
				@include subnav-ul;

				>li {
					position: relative;


					>a {
						@include subnav-anchor;
					}


					>ul {
						left: 100%;
						top: 0%;
						@include subnav-ul;

						>li {
							>a {
								@include subnav-anchor;
							}

							>ul {
								display: none;
							}
						}
					}

					&:hover>ul {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&:hover>ul {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__right {
		margin-left: auto;
		display: flex;
		align-items: center;

		.mobile-nav__toggler {
			@media (min-width: 1200px) {
				display: none;
			}

			span {
				display: block;
				width: 22px;
				height: 2px;
				background-color: $cretech-black;

				&:nth-child(2) {
					margin-top: 6px;
					margin-bottom: 6px;
				}
			}
		}

		.search-toggler {
			font-size: 24px;
			color: $cretech-black;
			border-left: 1px solid $cretech-border;
			padding-left: 15px;
			margin-left: 15px;
			padding-top: 5px;
			padding-bottom: 5px;

			@media (min-width: 992px) {
				margin-left: 30px;
				padding-left: 30px;
				padding-top: 9.5px;
				padding-bottom: 9.5px;
			}

			@media (min-width: 1200px) {
				margin-left: 0;
			}
		}
	}

	&__cta {
		display: none;
		@include cretech-gradient;
		align-items: center;
		justify-content: center;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
		color: #cdb6ff;
		line-height: 1;
		margin-left: 30px;
		padding-top: 20px;
		padding-bottom: 20px;

		@media (min-width: 992px) {
			padding-left: 60px;
			padding-right: 60px;
		}

		b {
			letter-spacing: 0;
			font-size: 16px;
			font-weight: bold;
			text-transform: capitalize;
			display: block;
			color: #fff;
			margin-bottom: 5px;

			background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 1px no-repeat;
			color: inherit;
			transition: all 500ms ease;

			&:hover {
				color: #fff;
				background-size: 100% 1px;
			}
		}

		@media (min-width: 768px) {
			display: flex;
		}

		>i {
			width: 40px;
			height: 40px;
			background-color: #fff;
			color: $cretech-secondary;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin-right: 20px;
			transition: all 500ms ease;

			@media (min-width: 992px) {
				width: 49px;
				height: 49px;
			}
		}

		&:hover {
			color: #fff;
		}

		&:hover>i {
			background-color: $cretech-black;
			color: #fff;
		}
	}
}



.sticky-header--cloned {
	position: fixed;
	z-index: 991;
	top: 0;
	left: 0;
	background-color: #fff;
	width: 100%;
	visibility: hidden;
	transform: translateY(-120%);
	transition: transform 500ms ease, visibility 500ms ease;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
	.sticky-header--cloned {
		display: none !important;
	}
}

.sticky-header--cloned.sticky-fixed {
	transform: translateY(0);
	visibility: visible;
}


.header-two {
	@media (min-width: 1200px) {
		position: absolute;
		top: 20px;
		left: 0;
		width: 100%;
		z-index: 91;
	}

	.main-menu {
		background-color: $cretech-black;
		box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);

		@media (min-width: 1200px) {
			background-color: rgba(0, 0, 0, 0);
			box-shadow: none;
		}
	}

	.sticky-header--cloned {
		background-color: $cretech-black;
		box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	}

	.main-menu__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 15px;
		padding-bottom: 15px;

		@media (min-width: 1200px) {
			background-color: $cretech-black;
			box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
			padding: 0 40px;
		}
	}

	.main-menu__logo {
		@media (min-width: 1200px) {
			display: none;
		}
	}

	.main-menu__list {
		margin-left: 0;
	}

	.main-menu__list>li>a {
		color: $cretech-link-light;
		padding: 19px 0;
	}

	.main-menu__list>li:hover>a,
	.main-menu__list>li.current>a {
		color: #ffffff;
	}

	.main-menu__right .search-toggler {
		border-left: 1px solid rgba(255, 255, 255, 0.1);
		color: #fff;

		@media (min-width: 1200px) {
			padding-top: 4.5px;
			padding-bottom: 4.5px;
			margin-left: 41px;
			padding-left: 30px;
		}
	}

	.main-menu__right .mobile-nav__toggler span {
		background-color: #fff;
	}

	.main-menu__cta {
		background-image: none;
		padding: 0;
		font-size: 16px;
		letter-spacing: 0;
		color: #fff;
	}

	.main-menu__cta>i {
		width: auto;
		height: auto;
		margin-right: 10px;
		color: #fff;
		background-color: rgba(255, 255, 255, 0);
	}

	.main-menu__cta b {
		font-weight: 500;
		margin-bottom: 0;
	}
}


.header-three {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 91;
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);

	.main-menu__list>li>a {
		color: #fff;
	}

	.main-menu__list>li:hover>a,
	.main-menu__list>li.current>a {
		color: $cretech-primary;
	}

	.sticky-header--cloned {
		background-color: $cretech-black;
	}

	.main-menu__right .mobile-nav__toggler span {
		background-color: #fff;
	}

	.main-menu__right .search-toggler {
		color: #fff;
		border-left: 1px solid rgba(255, 255, 255, 0.1);

		@media (min-width: 1200px) {
			border-left: 0;
			border-right: 1px solid rgba(255, 255, 255, 0.1);
			padding-right: 30px;
			margin-right: 42px;
		}
	}

	.main-menu__cta {
		background-image: none;
		background-color: rgba(0, 0, 0, 0);
		color: #fff;
		padding-left: 0;

		@media (min-width: 1200px) {
			margin-left: 0;
		}

		>i {
			background-color: rgba(255, 255, 255, 0.1);
			color: #fff;
		}

		&:hover {
			>i {
				background-color: #fff;
				color: $cretech-black;
			}
		}
	}
}