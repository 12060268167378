/*
  Theme Name: CreTech - IT Solutions & Technology React Template
  Theme URL: https://mediacity.co.in/cretech/
  Author: Mediacity
  Author URI: https://themeforest.net/user/media-city
  Creation Date: 06 September 2022
  Description: A default stylesheet for CreTech - IT Solutions & Technology React Template.
  Version: 1.2
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
* Variable
* Animations
* Common
* Main Menu
* Pricing
* Work Process
* Mobile Drawer
* Page Header
* Funfact
* Page Header
* Contact
* Google Map
* Footer
* Blog
* Project
* Search Popup
* Services
* Team
* Client Carousel
* About
* CTA
* Testimonials
--------------------------------------------------------------*/
/***********************
* Variable
***********************/
:root {
  --cretech-font: 'DM Sans', sans-serif;
  --cretech-primary: #D4AF37;
  --cretech-primary-rgb: #F0E68C;
  --cretech-secondary: #C5B358;
  --cretech-secondary-rgb: 156, 46, 222;
  --cretech-text: #797582;
  --cretech-text-rgb: 121, 117, 130;
  --cretech-text-light: #a39eae;
  --cretech-text-light-rgb: 163, 158, 174;
  --cretech-link-light: #877e9b;
  --cretech-link-light-rgb: 135, 126, 155;
  --cretech-black: #12062c;
  --cretech-black-rgb: 18, 6, 44;
  --cretech-bg-black: #0d0421;
  --cretech-bg-black-rgb: 13, 4, 33;
  --cretech-gray: #f9f6ff;
  --cretech-gray-rgb: 249, 246, 255;
  --cretech-border: #e1dbec;
}

/***********************
* Animations
***********************/
@-webkit-keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@-webkit-keyframes videoBtnAnim {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes videoBtnAnim {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.zoom-fade {
  -webkit-animation-name: zoom-fade;
  animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/***********************
* Common
***********************/
body {
  font-size: 16px;
  line-height: 1.875em;
  color: var(--cretech-text, #797582);
  font-weight: 500;
  font-family: var(--cretech-font, "DM Sans", sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--cretech-primary, #6a2dec);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.section-padding--top {
  padding-top: 80px;
}

@media (min-width: 992px) {
  .section-padding--top {
    padding-top: 120px;
  }
}

.section-padding--bottom {
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .section-padding--bottom {
    padding-bottom: 120px;
  }
}

.section-padding-lg--top {
  padding-top: 80px;
}

@media (min-width: 992px) {
  .section-padding-lg--top {
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .section-padding-lg--top {
    padding-top: 160px;
  }
}

.section-padding-lg--bottom {
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .section-padding-lg--bottom {
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .section-padding-lg--bottom {
    padding-bottom: 160px;
  }
}

.section-padding-xl--bottom {
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .section-padding-xl--bottom {
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .section-padding-xl--bottom {
    padding-bottom: 240px;
  }
}

@media (min-width: 1200px) {
  .section-has-bottom-margin {
    margin-bottom: -120px;
  }
}

.thm-owl__carousel--range__input {
  max-width: 830px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -9px;
  margin-top: 20px;
}

.thm-owl__carousel--range__input .irs-min,
.thm-owl__carousel--range__input .irs-single,
.thm-owl__carousel--range__input .irs-max {
  visibility: hidden !important;
}

.thm-owl__carousel--range__input .irs--flat .irs-line {
  height: 2px;
  background-color: var(--cretech-gray, #f9f6ff);
}

.thm-owl__carousel--range__input .irs--flat .irs-bar {
  border-radius: 0;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  height: 2px;
}

.thm-owl__carousel--range__input .irs--flat .irs-handle {
  width: 20px;
  height: 10px;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  background-color: transparent;
  top: 21px;
}

.thm-owl__carousel--range__input .irs--flat .irs-handle>i:first-child {
  display: none;
}

.gray-bg {
  background-color: var(--cretech-gray, #f9f6ff);
}

.black-bg {
  background-color: var(--cretech-black, #12062c);
}

.background-size-cover {
  background-size: cover;
}

.background-repeat-no {
  background-repeat: no-repeat;
}

.background-position-top-right {
  background-position: top right;
}

.background-position-top-center {
  background-position: top center;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gutter-y-60 {
  --bs-gutter-y: 60px;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.thm-owl__carousel--with-shadow .owl-stage-outer {
  overflow: visible;
}

.thm-owl__carousel--with-shadow .owl-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 500ms ease, opacity 500ms ease;
  transition: visibility 500ms ease, opacity 500ms ease;
}

.thm-owl__carousel--with-shadow .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: var(--cretech-primary, #6a2dec);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: var(--cretech-secondary, #9c2ede);
}

.scroll-to-top:hover i {
  color: #fff;
}

.thm-btn {
  border: none;
  outline: none;
  background-color: var(--cretech-primary, #6a2dec);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .1em;
  display: inline-block;
  padding: 15px 49px;
  overflow: hidden;
  -webkit-transition: all .35s;
  transition: all .35s;
  position: relative;
}

.thm-btn span {
  position: relative;
  z-index: 2;
}

.thm-btn:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.thm-btn:before,
.thm-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  -webkit-transition: all .35s;
  transition: all .35s;
}

.thm-btn:before {
  opacity: .5;
}

.thm-btn:after {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.thm-btn:hover:before,
.thm-btn:hover:after {
  top: 0;
}

.thm-btn--light {
  background-color: #fff;
  color: var(--cretech-black, #12062c);
}

.thm-btn--light:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.thm-btn--dark {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.thm-btn--dark:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.section-title {
  padding-bottom: 50px;
}

.section-title__text {
  margin: 0;
  text-transform: uppercase;
  color: var(--cretech-secondary, #9c2ede);
  letter-spacing: .1em;
  font-size: 16px;
  font-weight: bold;
  margin-top: -10px;
}

.section-title__text--light {
  color: #fff;
}

.section-title__title {
  margin: 0;
  color: var(--cretech-primary);
  font-size: 35px;
  letter-spacing: -0.05em;
  font-weight: bold;
  margin-top: 3px;
}

.section-title__title br {
  display: none;
}

@media (min-width: 768px) {
  .section-title__title br {
    display: inherit;
  }
}

@media (min-width: 768px) {
  .section-title__title {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .section-title__title {
    font-size: 48px;
  }
}

.section-title__title--light {
  color: #fff;
}

.preloader {
  position: fixed;
  background-color: var(--cretech-bg-black, #0d0421);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
}

.preloader__circle {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--cretech-secondary, #9c2ede);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.preloader__circle:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--cretech-primary, #6a2dec);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.preloader__circle:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--cretech-primary, #6a2dec);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--cretech-primary, #6a2dec);
  opacity: .3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width .3s, height .3s, opacity .3s;
  transition: width .3s, height .3s, opacity .3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--cretech-primary, #6a2dec);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: .4;
}

/***********************
* Main Menu
***********************/
.topbar {
  background-color: var(--cretech-black, #12062c);
  display: none;
}

@media (min-width: 835px) {
  .topbar {
    display: block;
  }
}

.topbar .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (min-width: 768px) {
  .topbar .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .topbar .container-fluid {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.topbar__text {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: var(--cretech-text-light, #a39eae);
}

.topbar__info {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.topbar__info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--cretech-text-light, #a39eae);
}

.topbar__info li+li {
  margin-left: 20px;
}

@media (min-width: 1200px) {
  .topbar__info li+li {
    margin-left: 30px;
  }
}

.topbar__info li i {
  color: #ab32f2;
  margin-right: 10px;
}

.topbar__info li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.topbar__info li a:hover {
  color: #fff;
}

.topbar__social {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
  display: none;
}

@media (min-width: 1050px) {
  .topbar__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .topbar__social {
    margin-left: 60px;
  }
}

.topbar__social li+li {
  margin-left: 27px;
}

.topbar__social li a {
  color: #fff;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.topbar__social li a:hover {
  color: var(--cretech-primary, #6a2dec);
}

.topbar-two {
  display: none;
}

@media (min-width: 1200px) {
  .topbar-two {
    display: block;
  }
}

.topbar-two__inner {
  background-color: #fff;
  padding: 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.topbar-two__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}

.topbar-two__list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--cretech-text, #797582);
  letter-spacing: -0.05em;
  line-height: 1;
}

.topbar-two__list__item a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.topbar-two__list__item a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.topbar-two__list__item span {
  display: block;
  color: var(--cretech-black, #12062c);
  font-weight: bold;
  margin-bottom: 5px;
}

.topbar-two__list__item+.topbar-two__list__item {
  margin-left: 40px;
}

.topbar-two__list__icon {
  width: 40px;
  height: 40px;
  background-color: var(--cretech-secondary, #9c2ede);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-right: 20px;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.topbar-two__list__icon:hover {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.topbar-two__list__icon i.map-marker {
  font-size: 13px;
}

.topbar-two__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar-two__btn {
  font-size: 12px;
  padding: 9.5px 39px;
}

.main-menu .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .main-menu .container-fluid {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .main-menu .container-fluid {
    padding-left: 60px;
    padding-right: 0px;
  }
}

.main-menu__list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1500px) {
  .main-menu__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
  }
}

.main-menu__list>li {
  position: relative;
}

.main-menu__list>li+li {
  margin-left: 40px;
}

@media (min-width: 1270px) {
  .main-menu__list>li+li {
    margin-left: 58px;
  }
}

.main-menu__list>li>a {
  text-transform: uppercase;
  color: var(--cretech-text, #797582);
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  padding: 29.5px 0;
}

.main-menu__list>li:hover>a,
.main-menu__list>li.current>a {
  text-shadow: 0 0 1px currentColor;
  color: var(--cretech-black, #12062c);
}

.main-menu__list>li>ul {
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  min-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

.main-menu__list .menu-item-custom-width {
  min-width: 235px !important;
}

.main-menu__list>li>ul>li {
  position: relative;
}

.main-menu__list>li>ul>li>a {
  text-transform: uppercase;
  color: var(--cretech-text, #797582);
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  padding: 7px 20px;
}

.main-menu__list>li>ul>li>a:hover {
  background-color: var(--cretech-primary, #6a2dec);
  color: #fff;
}

.main-menu__list>li>ul>li>ul {
  left: 100%;
  top: 0%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  min-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

.main-menu__list>li>ul>li>ul>li>a {
  text-transform: uppercase;
  color: var(--cretech-text, #797582);
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  padding: 7px 20px;
}

.main-menu__list>li>ul>li>ul>li>a:hover {
  background-color: var(--cretech-primary, #6a2dec);
  color: #fff;
}

.main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
}

.main-menu__list>li:hover>ul {
  opacity: 1;
  visibility: visible;
}

.main-menu__right {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 1500px) {
  .main-menu__right .mobile-nav__toggler {
    display: none;
  }
}

@media (max-width: 1500px) {
  .mobile-nav__toggler {
    display: block !important;
  }
}

.main-menu__right .mobile-nav__toggler span {
  display: block;
  width: 22px;
  height: 2px;
  background-color: var(--cretech-black, #12062c);
}

.main-menu__right .mobile-nav__toggler span:nth-child(2) {
  margin-top: 6px;
  margin-bottom: 6px;
}

.main-menu__right .search-toggler {
  font-size: 24px;
  color: var(--cretech-black, #12062c);
  border-left: 1px solid var(--cretech-border, #e1dbec);
  padding-left: 15px;
  margin-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 992px) {
  .main-menu__right .search-toggler {
    margin-left: 30px;
    padding-left: 30px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
  }
}

@media (min-width: 1200px) {
  .main-menu__right .search-toggler {
    margin-left: 0;
  }
}

.main-menu__cta {
  display: none;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: #cdb6ff;
  line-height: 1;
  margin-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .main-menu__cta {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.main-menu__cta b {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: block;
  color: #fff;
  margin-bottom: 5px;
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__cta b:hover {
  color: #fff;
  background-size: 100% 1px;
}

@media (min-width: 1500px) {
  .main-menu__cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu__cta>i {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: var(--cretech-secondary, #9c2ede);
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 992px) {
  .main-menu__cta>i {
    width: 49px;
    height: 49px;
  }
}

.main-menu__cta:hover {
  color: #fff;
}

.main-menu__cta:hover>i {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.sticky-header--cloned {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .sticky-header--cloned {
    display: none !important;
  }
}

.sticky-header--cloned.sticky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

@media (min-width: 1200px) {
  .header-two {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    z-index: 91;
  }
}

.header-two .main-menu {
  background-color: var(--cretech-black, #12062c);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

@media (min-width: 1200px) {
  .header-two .main-menu {
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.header-two .sticky-header--cloned {
  background-color: var(--cretech-black, #12062c);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.header-two .main-menu__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .header-two .main-menu__inner {
    background-color: var(--cretech-black, #12062c);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
    padding: 0 40px;
  }
}

@media (min-width: 1200px) {
  .header-two .main-menu__logo {
    display: none;
  }
}

.header-two .main-menu__list {
  margin-left: 0;
}

.header-two .main-menu__list>li>a {
  color: var(--cretech-link-light, #877e9b);
  padding: 19px 0;
}

.header-two .main-menu__list>li:hover>a,
.header-two .main-menu__list>li.current>a {
  color: #ffffff;
}

.header-two .main-menu__right .search-toggler {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}

@media (min-width: 1200px) {
  .header-two .main-menu__right .search-toggler {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    margin-left: 41px;
    padding-left: 30px;
  }
}

.header-two .main-menu__right .mobile-nav__toggler span {
  background-color: #fff;
}

.header-two .main-menu__cta {
  background-image: none;
  padding: 0;
  font-size: 16px;
  letter-spacing: 0;
  color: #fff;
}

.header-two .main-menu__cta>i {
  width: auto;
  height: auto;
  margin-right: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
}

.header-two .main-menu__cta b {
  font-weight: 500;
  margin-bottom: 0;
}

.header-three {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 91;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-three .main-menu__list>li>a {
  color: #fff;
}

.header-three .main-menu__list>li:hover>a,
.header-three .main-menu__list>li.current>a {
  color: var(--cretech-primary, #6a2dec);
}

.header-three .sticky-header--cloned {
  background-color: var(--cretech-black, #12062c);
}

.header-three .main-menu__right .mobile-nav__toggler span {
  background-color: #fff;
}

.header-three .main-menu__right .search-toggler {
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 1200px) {
  .header-three .main-menu__right .search-toggler {
    border-left: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 30px;
    margin-right: 42px;
  }
}

.header-three .main-menu__cta {
  background-image: none;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  padding-left: 0;
}

@media (min-width: 1200px) {
  .header-three .main-menu__cta {
    margin-left: 0;
  }
}

.header-three .main-menu__cta>i {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.header-three .main-menu__cta:hover>i {
  background-color: #fff;
  color: var(--cretech-black, #12062c);
}

/***********************
* Pricing
***********************/
.pricing-one {
  position: relative;
}

.pricing-one__shape {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  height: 688px;
  display: none;
}

@media (min-width: 1200px) {
  .pricing-one__shape {
    display: block;
  }
}

.pricing-one .container {
  position: relative;
}

.pricing-one .section-title {
  padding-bottom: 30px;
}

.pricing-one__text {
  margin-bottom: 39px;
}

.pricing-one__content {
  padding-bottom: 130px;
}

@media (min-width: 992px) {
  .pricing-one__content {
    padding-bottom: 0;
  }
}

.pricing-one__items {
  position: relative;
}

.pricing-one__items::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background-image: url(../images/shapes/pricing-one-bg-1-1.png);
  background-position: left center;
  background-repeat: no-repeat;
}

.pricing-one__items::before {
  position: absolute;
  content: '';
  width: 10000px;
  left: 170px;
  background-color: var(--cretech-primary, #6a2dec);
  top: -80px;
  bottom: -50px;
}

@media (min-width: 768px) {
  .pricing-one__items::before {
    bottom: -80px;
  }
}

@media (min-width: 992px) {
  .pricing-one__items::before {
    top: -120px;
    bottom: -120px;
  }
}

.pricing-one__circle {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 45px;
  margin-top: 6px;
}

@media (min-width: 1200px) {
  .pricing-one__circle {
    padding-right: 45px;
  }
}

.pricing-one__circle .circle-progress {
  position: relative;
  display: block;
  width: 125px;
  height: 125px;
}

.pricing-one__circle .circle-progress span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  color: var(--cretech-black, #12062c);
  font-weight: bold;
  line-height: 1em;
}

.pricing-one__circle__title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: var(--cretech-black, #12062c);
  letter-spacing: -0.05em;
  margin-top: 15px;
}

@media (min-width: 992px) {
  .pricing-one__circle__title {
    text-align: center;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .pricing-one__circle__title {
    padding-left: 15px;
    padding-right: 0;
    text-align: left;
  }
}

.pricing-card-one {
  background-image: url(../images/shapes/price-card-bg-1-1.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  padding: 50px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .pricing-card-one {
    margin-bottom: 0;
  }
}

.pricing-card-one__name {
  text-align: right;
  text-transform: uppercase;
  color: var(--cretech-black, #12062c);
  letter-spacing: .1em;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 13px;
  margin-bottom: 2px;
}

.pricing-card-one__price {
  text-align: right;
  font-size: 38px;
  font-weight: bold;
  color: var(--cretech-primary, #6a2dec);
  line-height: 1em;
  margin-top: 11px;
}

@media (min-width: 768px) {
  .pricing-card-one__price {
    font-size: 45px;
  }
}

@media (min-width: 992px) {
  .pricing-card-one__price {
    font-size: 52px;
  }
}

@media (min-width: 1200px) {
  .pricing-card-one__price {
    font-size: 60px;
  }
}

.pricing-card-one__time {
  text-align: right;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 33px;
}

.pricing-card-one__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}

.pricing-card-one__list li {
  position: relative;
  padding-left: 37px;
  font-weight: 400;
}

.pricing-card-one__list li+li {
  margin-top: 8px;
}

.pricing-card-one__list li>i {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 16px;
  color: var(--cretech-secondary, #9c2ede);
}

.pricing-card-one__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/***********************
* Work Process
***********************/
.work-process {
  counter-reset: workProcessCount;
}

.work-process-card-one {
  counter-increment: workProcessCount;
  border: 2px solid var(--cretech-primary, #6a2dec);
  padding: 38px;
  margin-bottom: 38px;
  position: relative;
  padding-bottom: 30px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.work-process-card-one:hover {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .work-process-card-one {
    margin-bottom: 0;
    margin-right: 38px;
  }
}

.work-process-card-one::before {
  content: '';
  background-color: var(--cretech-primary, #6a2dec);
  width: 74px;
  height: 74px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  margin-top: -36px;
}

@media (min-width: 992px) {
  .work-process-card-one::before {
    left: 100%;
    -webkit-transform: translateX(0%) translateY(-50%);
    transform: translateX(0%) translateY(-50%);
    top: 50%;
    -webkit-clip-path: polygon(0 0, 50% 50%, 0 100%, 0% 50%);
    clip-path: polygon(0 0, 50% 50%, 0 100%, 0% 50%);
    margin-top: 0;
    width: 77px;
    height: 77px;
  }
}

.work-process-card-one::after {
  content: '';
  background-color: #ffffff;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  margin-top: -22px;
}

@media (min-width: 992px) {
  .work-process-card-one::after {
    left: 100%;
    -webkit-transform: translateX(0%) translateY(-50%);
    transform: translateX(0%) translateY(-50%);
    top: 50%;
    -webkit-clip-path: polygon(0 0, 50% 50%, 0 100%, 0% 50%);
    clip-path: polygon(0 0, 50% 50%, 0 100%, 0% 50%);
    margin-top: 0;
  }
}

.work-process-card-one__count {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  position: relative;
  margin-bottom: 19px;
  width: 50px;
  height: 50px;
  background-color: var(--cretech-secondary, #9c2ede);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.work-process-card-one__count::after {
  content: counters(workProcessCount, ".");
}

.work-process-card-one__title {
  position: relative;
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-bottom: 14px;
}

.work-process-card-one__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.work-process-card-one__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.work-process-card-one__text {
  margin-bottom: 19px;
}

.work-process-card-one__link {
  position: relative;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 14px;
  font-weight: bold;
  color: var(--cretech-primary, #6a2dec);
  letter-spacing: .1em;
  line-height: 1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.work-process-card-one__link:hover {
  color: var(--cretech-black, #12062c);
}

/***********************
* Mobile Drawer
***********************/
.mobile-nav__wrapper {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.9;
}

.mobile-nav__content {
  width: 100%;
  max-width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  left: auto !important;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  background-color: var(--cretech-bg-black, #0d0421);
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 30px 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__content::-webkit-scrollbar {
  display: none;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 30px;
  margin-bottom: 10px;
}

.mobile-menu__list .menu-item-has-children button {
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--cretech-black, #12062c);
  border: none;
  outline: none;
  float: right;
  margin-top: 5px;
}

.mobile-menu__list li.current>a {
  color: #fff;
}

.mobile-menu__list>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu__list>li>a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: var(--cretech-link-light, #877e9b);
  display: block;
  line-height: 40px;
}

.mobile-menu__list>li>ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  padding-left: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu__list>li>ul>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu__list>li>ul>li>a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: var(--cretech-link-light, #877e9b);
  display: block;
  line-height: 40px;
}

.mobile-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 5px;
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu__list>li>ul>li>ul>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu__list>li>ul>li>ul>li>a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: var(--cretech-link-light, #877e9b);
  display: block;
  line-height: 40px;
}

.mobile-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.mobile-nav__social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -7px;
  margin-right: -7px;
}

.mobile-nav__social li {
  margin: 0 7px;
}

.mobile-nav__social li a {
  width: 40px;
  height: 40px;
  border: 1px solid var(--cretech-primary, #6a2dec);
  color: #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
}

.mobile-nav__social li a:hover {
  color: #fff;
  background-color: var(--cretech-primary, #6a2dec);
}

.mobile-nav__contact {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  position: relative;
  padding-left: 33px;
  color: var(--cretech-link-light, #877e9b);
  font-size: 16px;
  line-height: 36px;
}

.mobile-nav__contact li>i {
  font-size: 16px;
  color: var(--cretech-secondary, #9c2ede);
  position: absolute;
  top: 11px;
  left: 0;
}

.mobile-nav__contact li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-nav__contact li a:hover {
  color: #fff;
}

.mobile-nav__close {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: 20px;
  right: 15px;
}

.mobile-nav__close span {
  width: 2px;
  height: 100%;
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.mobile-nav__close span:nth-child(2) {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

/***********************
* Page Header
***********************/
.slider-one {
  position: relative;
  background-color: var(--cretech-black, #12062c);
}

.slider-one .item {
  background-color: var(--cretech-black, #12062c);
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .slider-one .item {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media (min-width: 1200px) {
  .slider-one .item {
    min-height: 794px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.slider-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cretech-black, #12062c);
  mix-blend-mode: luminosity;
  opacity: 0.7;
  background-size: cover;
}

.slider-one__line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/shapes/slider-one-top-l-1.png);
  background-repeat: repeat-x;
  display: none;
  -webkit-animation: bgSlide 20s linear infinite;
  animation: bgSlide 20s linear infinite;
}

@media (min-width: 992px) {
  .slider-one__line {
    display: block;
  }
}

.slider-one__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../images/shapes/slider-one-shape-1.png);
  background-repeat: no-repeat;
  background-position: top right;
  mix-blend-mode: soft-light;
  width: 585px;
  height: 100%;
  max-width: 100%;
  background-size: cover;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
  opacity: 0;
}

@media (min-width: 992px) {
  .slider-one__shape-1 {
    display: block;
  }
}

.active .slider-one__shape-1 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.slider-one__shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../images/shapes/slider-one-shape-2.png);
  background-repeat: no-repeat;
  mix-blend-mode: overlay;
  background-position: top right;
  width: 643px;
  max-width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
  opacity: 0;
}

@media (min-width: 992px) {
  .slider-one__shape-2 {
    display: block;
  }
}

.active .slider-one__shape-2 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 2100ms;
  transition-delay: 2100ms;
}

.slider-one__shape-3 {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../images/shapes/slider-one-shape-3.png);
  background-repeat: no-repeat;
  background-position: top right;
  mix-blend-mode: screen;
  width: 585px;
  height: 100%;
  max-width: 100%;
  background-size: cover;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
  opacity: 0;
}

@media (min-width: 992px) {
  .slider-one__shape-3 {
    display: block;
  }
}

.active .slider-one__shape-3 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.slider-one__content {
  position: relative;
}

.slider-one__floated {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  position: absolute;
  position: absolute;
  left: -65px;
  top: 28px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.1);
  line-height: 1;
}

@media (min-width: 1200px) {
  .slider-one__floated {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.slider-one__floated span {
  text-align: center;
  -webkit-transform: rotate(-90deg) translateY(-40px);
  transform: rotate(-90deg) translateY(-40px);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 1500ms;
  transition-delay: 1500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
}

.slider-one__floated .char1 {
  margin-top: 4px;
}

.slider-one__floated .char2 {
  margin-top: 4px;
}

.slider-one__floated .char3 {
  margin-top: 4px;
}

.slider-one__floated .char4 {
  margin-top: 4px;
}

.slider-one__floated .char5 {
  margin-top: 4px;
}

.slider-one__floated .char6 {
  margin-top: 4px;
}

.slider-one__floated .char7 {
  margin-top: 4px;
}

.slider-one__floated .char8 {
  margin-top: 9px;
}

.slider-one__floated .char9 {
  margin-top: 4px;
}

.active .slider-one__floated span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(-90deg) translateY(0px);
  transform: rotate(-90deg) translateY(0px);
}

.slider-one__text {
  margin: 0;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: .1em;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 1500ms;
  transition-delay: 1500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
}

@media (min-width: 768px) {
  .slider-one__text {
    font-size: 18px;
  }
}

.active .slider-one__text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slider-one__title {
  font-size: 38px;
  line-height: 1.142em;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 25px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease,
    color 500ms ease,
    background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease,
    color 500ms ease,
    background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease,
    opacity 2000ms ease,
    color 500ms ease,
    background 500ms ease;
  transition: transform 2000ms ease,
    opacity 2000ms ease,
    color 500ms ease,
    background 500ms ease,
    -webkit-transform 2000ms ease;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.slider-one__title-wrapper {
  overflow: hidden;
}

@media (min-width: 768px) {
  .slider-one__title {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .slider-one__title {
    font-size: 56px;
    margin-top: 16px;
  }
}

@media (min-width: 1200px) {
  .slider-one__title {
    font-size: 84px;
  }
}

.slider-one__title br {
  display: none;
}

@media (min-width: 992px) {
  .slider-one__title br {
    display: inherit;
  }
}

.active .slider-one__title {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slider-one__btns {
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
}

.active .slider-one__btns {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slider-one .owl-theme .owl-nav {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
}

@media (min-width: 576px) {
  .slider-one .owl-theme .owl-nav {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .slider-one .owl-theme .owl-nav {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .slider-one .owl-theme .owl-nav {
    max-width: 960px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .slider-one .owl-theme .owl-nav {
    bottom: 80px;
    max-width: 1200px;
  }
}

.slider-one .owl-theme .owl-nav [class*=owl-] {
  width: 51px;
  height: 51px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: var(--cretech-black, #12062c);
  opacity: .15;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  margin: 0;
}

.slider-one .owl-theme .owl-nav [class*=owl-]:hover {
  opacity: 1;
}

.slider-one .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
  margin-left: 10px;
}

.slider-one .owl-theme .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 576px) {
  .slider-one .owl-theme .owl-dots {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .slider-one .owl-theme .owl-dots {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .slider-one .owl-theme .owl-dots {
    max-width: 960px;
    height: 51px;
  }
}

@media (min-width: 1200px) {
  .slider-one .owl-theme .owl-dots {
    bottom: 80px;
    max-width: 1200px;
  }
}

.slider-one .owl-theme .owl-dots .owl-dot+.owl-dot {
  margin-left: 10px;
}

.slider-one .owl-theme .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #fff;
  opacity: 0.4;
  -webkit-transition: opacity 500ms ease, -webkit-transition 500ms ease;
  transition: opacity 500ms ease, -webkit-transition 500ms ease;
  transition: opacity 500ms ease, transition 500ms ease;
  transition: opacity 500ms ease, transition 500ms ease, -webkit-transition 500ms ease;
  display: block;
  margin: 0;
}

.slider-one .owl-theme .owl-dots .owl-dot:hover span,
.slider-one .owl-theme .owl-dots .owl-dot.active span {
  opacity: 1;
  -webkit-transform: scale(1.44);
  transform: scale(1.44);
}

.slider-two {
  position: relative;
}

@media (min-width: 1200px) {
  .slider-two .item {
    min-height: 870px;
  }
}

.slider-two .slider-one__btns {
  margin-top: 5px;
}

@media (min-width: 1200px) {
  .slider-two .slider-one__content {
    padding-top: 172px;
  }
}

.slider-two__social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 50%;
  left: 60px;
  z-index: 10;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 70px;
}

@media (min-width: 1200px) {
  .slider-two__social {
    display: block;
  }
}

.slider-two__social li+li {
  margin-top: 45px;
}

.slider-two__social li a {
  color: #fff;
  font-size: 16px;
  display: block;
  line-height: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.slider-two__social li a:hover {
  color: var(--cretech-primary, #6a2dec);
}

.slider-two .slider-one__shape-1 {
  max-width: 330px;
  background-image: url(../images/shapes/slider-two-shape-1-1.png);
  mix-blend-mode: normal;
}

.slider-two .slider-one__title {
  text-transform: uppercase;
  margin-top: 0;
}

.slider-two .slider-one__title span {
  position: relative;
}

.slider-two .slider-one__title span::before {
  content: '';
  background-image: url(../images/shapes/slider-two-title-s-1-1.png);
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  -webkit-animation-name: zoom-fade;
  animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@media (min-width: 992px) {
  .slider-two .owl-theme .owl-dots {
    display: none;
  }
}

.slider-two .owl-theme .owl-nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (min-width: 992px) {
  .slider-two .owl-theme .owl-nav {
    top: 50%;
    bottom: auto;
  }
}

.slider-two .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
  margin-left: 0;
  margin-top: 10px;
}

.slider-three {
  background-color: #0d0421;
}

.slider-three .slider-one__bg {
  background-color: #0d0421;
  opacity: 0.5;
}

.slider-three .item {
  background-color: #0d0421;
  padding-top: 170px;
}

@media (min-width: 992px) {
  .slider-three .item {
    padding-top: 282px;
  }
}

@media (min-width: 1200px) {
  .slider-three .item {
    min-height: 900px;
  }
}

.slider-three .slider-one__shape-1 {
  max-width: 621px;
  background-image: url(../images/shapes/slider-three-shape-3.png);
  top: auto;
  bottom: 0;
  height: 767px;
  mix-blend-mode: normal;
}

.slider-three .slider-one__shape-2 {
  max-width: 426px;
  height: 275px;
  background-image: url(../images/shapes/slider-three-shape-4.png);
  top: auto;
  right: auto;
  bottom: 0;
  mix-blend-mode: normal;
  left: 0;
}

.slider-three .slider-one__floated {
  position: relative;
  top: auto;
  left: auto;
  font-size: 62px;
  letter-spacing: .5em;
  margin-bottom: -35px;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 2000ms;
  transition-delay: 2000ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease, background 500ms ease, -webkit-transform 2000ms ease;
  color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 992px) {
  .slider-three .slider-one__floated {
    display: block;
  }
}

@media (min-width: 1200px) {
  .slider-three .slider-one__floated {
    font-size: 82px;
  }
}

.slider-three .slider-one__floated span {
  -webkit-transform: rotate(0deg) translateY(0px);
  transform: rotate(0deg) translateY(0px);
}

.slider-three .slider-one__floated [class*=char] {
  margin: 0;
}

.slider-three .active .slider-one__floated {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slider-three .owl-theme .owl-dots {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .slider-three .owl-theme .owl-dots {
    display: none;
  }
}

.slider-three .owl-theme .owl-nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .slider-three .owl-theme .owl-nav {
    max-width: 100%;
    bottom: auto;
    padding-left: 55px;
    padding-right: 55px;
    top: 50%;
  }
}

@media (min-width: 1200px) {
  .slider-three .owl-theme .owl-nav {
    bottom: auto;
    max-width: 100%;
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 30px;
  }
}

/***********************
* Funfact
***********************/
.funfact-one {
  padding-top: 80px;
}

@media (min-width: 1200px) {
  .funfact-one {
    padding-top: 0;
  }
}

.funfact-one__inner {
  padding-top: 45px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-position: center center;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
}

@media (min-width: 992px) {
  .funfact-one__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .funfact-one__inner {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 70.5px;
    padding-bottom: 73px;
  }
}

.funfact-one__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 768px) {
  .funfact-one__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }
}

.funfact-one__list__item:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .funfact-one__list__item:not(:last-of-type) {
    margin-bottom: 0;
  }
}

.funfact-one__list__title {
  margin: 0;
  color: #fff;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -0.025em;
}

@media (min-width: 768px) {
  .funfact-one__list__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .funfact-one__list__title {
    font-size: 48px;
    margin-bottom: 4px;
  }
}

.funfact-one__list__text {
  margin: 0;
  color: #e4d3ff;
}

/***********************
* Page Header
***********************/
.page-header {
  background-color: var(--cretech-black, #12062c);
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (min-width: 768px) {
  .page-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.page-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/shapes/page-header-1-1.png);
  background-repeat: no-repeat;
  background-position: top right;
  z-index: 1;
}

.page-header__bg {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cretech-black, #12062c);
  mix-blend-mode: luminosity;
  opacity: .7;
}

.page-header .container {
  position: relative;
  z-index: 3;
}

.page-header .thm-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-header .thm-breadcrumb li {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: .1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-header .thm-breadcrumb li:not(:last-child)::after {
  content: '/';
  margin-left: 10px;
  margin-right: 10px;
}

.page-header .thm-breadcrumb li a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 77%/0px 1px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 77%/0px 1px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.page-header .thm-breadcrumb li a:hover {
  background-size: 100% 1px;
}

.page-header__title {
  margin: 0;
  color: #fff;
  letter-spacing: -.05em;
  font-size: 38px;
  margin-top: 2px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .page-header__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .page-header__title {
    font-size: 48px;
  }
}

/***********************
* Contact
***********************/
.contact-one__form {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--cretech-gray, #f9f6ff);
}

@media (min-width: 768px) {
  .contact-one__form {
    padding: 80px 40px;
  }
}

@media (min-width: 992px) {
  .contact-one__form {
    padding: 80px;
  }
}

.contact-one__form .row {
  --bs-gutter-y: 20px;
  --bs-gutter-x: 20px;
}

.contact-one__form label {
  display: block;
}

.contact-one__form input[type=text],
.contact-one__form select,
.contact-one__form .nice-select,
.contact-one__form input[type=email] {
  display: block;
  border: none;
  outline: none;
  background-color: #fff;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
  color: var(--cretech-text, #797582);
  height: 56px;
  border-radius: 0;
}

.contact-one__form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="currentColor" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 30px top 50%;
  background-size: .65em auto;
}

.contact-one__form .nice-select span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  font-size: inherit;
  font-weight: 500;
  color: inherit;
}

.contact-one__form .nice-select .list {
  width: 100%;
  border-radius: 0;
}

.contact-one__form .nice-select .list li {
  text-transform: uppercase;
  color: var(--cretech-text, #797582);
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  padding: 2px 20px;
}

.contact-one__form .nice-select .list li:hover {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.contact-one__form .nice-select:after {
  content: '\f107';
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: none;
  width: auto;
  height: auto;
  -webkit-transform: rotate(0deg) translateY(-50%);
  transform: rotate(0deg) translateY(-50%);
  top: 50%;
  right: 30px;
  color: var(--cretech-text, #797582);
  margin: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  font-size: 14px;
}

.contact-one__form .nice-select.open:after {
  content: '\f106';
}

.contact-one__form textarea {
  display: block;
  border: none;
  outline: none;
  background-color: #fff;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
  color: var(--cretech-text, #797582);
  height: 138px;
  padding-top: 20px;
}

@media (min-width: 992px) {
  .contact-one .container>.row>[class*=col-] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.contact-one__info {
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .contact-one__info {
    margin-left: -30px;
    min-width: 400px;
  }
}

.contact-one__info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  opacity: 0.93;
}

.contact-one__item {
  position: relative;
}

.contact-one__item__title {
  margin: 0;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .1em;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.667em;
  margin-top: -9px;
}

.contact-one__item__text {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.75em;
  margin: 0;
  margin-top: 4px;
}

.contact-one__item__text a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 80%/0px 1px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 80%/0px 1px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-one__item__text a:hover {
  color: #fff;
  background-size: 100% 1px;
}

.contact-one__item+.contact-one__item {
  margin-top: 38px;
}

.contact-one__social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -7px;
  margin-right: -7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.contact-one__social li {
  margin: 0 7px;
}

.contact-one__social li a {
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
}

.contact-one__social li a:hover {
  color: var(--cretech-black, #12062c);
  background-color: #fff;
}

.contact-two {
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  position: relative;
  z-index: 10;
}

.contact-two::before {
  content: '';
  background-image: url(../images/shapes/contact-two-bg-1-1.png);
  background-position: top left;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contact-two .container {
  position: relative;
}

.contact-two__video {
  width: 100px;
  height: 100px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: var(--cretech-primary, #6a2dec);
  -webkit-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.contact-two__video .ripple,
.contact-two__video .ripple:before,
.contact-two__video .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.contact-two__video .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
}

.contact-two__video .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
}

@media (min-width: 1200px) {
  .contact-two__video {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 0;
    left: -100px;
  }
}

.contact-two__video:hover {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.contact-two__image {
  position: relative;
  display: inline-block;
}

.contact-two__image::after {
  content: '';
  width: 30px;
  height: 100px;
  top: calc(100% + 140px);
  left: calc(100% + 20px);
  background-color: var(--cretech-primary, #6a2dec);
  position: absolute;
}

.contact-two__image img {
  max-width: 100%;
}

@media (min-width: 1200px) {
  .contact-two__image img {
    margin-bottom: -240px;
  }
}

.contact-two .contact-one__form {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

.contact-two .contact-one__form input[type=text],
.contact-two .contact-one__form select,
.contact-two .contact-one__form .nice-select,
.contact-two .contact-one__form textarea,
.contact-two .contact-one__form input[type=email] {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.contact-two .contact-one__form textarea {
  height: 120px;
}

.contact-two .contact-one__form .nice-select::after {
  color: #fff;
}

/***********************
* Google Map
***********************/
/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
  display: block;
  background-color: var(--cretech-black, #12062c);
  border: none;
  display: block;
  width: 100%;
  mix-blend-mode: luminosity;
  height: 485px;
}

.google-map iframe {
  display: block;
  border: none;
  width: 100%;
  height: 100%;
}

/***********************
* Footer
***********************/
.footer-main {
  background-image: url(../images/background/footer-bg-1-1.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-color: var(--cretech-black, #12062c);
  background-blend-mode: luminosity;
}

.footer-main--top-padding {
  padding-top: 160px;
  margin-top: -80px;
}

@media (min-width: 992px) {
  .footer-main--top-padding {
    padding-top: 240px;
    margin-top: -120px;
  }
}

@media (min-width: 1200px) {
  .footer-widget--links {
    margin-left: 70px;
  }
}

.footer-widget__logo {
  display: inline-block;
  margin-top: -8px;
}

.footer-widget__text {
  margin: 0;
  color: var(--cretech-link-light, #877e9b);
}

.footer-widget--about .footer-widget__text {
  margin-top: 34px;
  margin-bottom: 20px;
}

.footer-widget--newsletter .footer-widget__text {
  font-weight: 400;
  color: var(--cretech-text-light, #a39eae);
}

.footer-widget__title {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-top: -4px;
  margin-bottom: 34px;
}

@media (min-width: 768px) {
  .footer-widget__title {
    font-size: 24px;
  }
}

.footer-widget__title::after {
  content: '';
  width: 31px;
  height: 2px;
  display: block;
  background-color: var(--cretech-secondary, #9c2ede);
  margin-top: 8px;
}

.footer-widget__social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -7px;
  margin-right: -7px;
}

.footer-widget__social li {
  margin: 0 7px;
}

.footer-widget__social li a {
  width: 40px;
  height: 40px;
  border: 1px solid var(--cretech-primary, #6a2dec);
  color: #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
}

.footer-widget__social li a:hover {
  color: #fff;
  background-color: var(--cretech-primary, #6a2dec);
}

.footer-widget__links {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -2px;
  margin-bottom: -12px;
}

.footer-widget__links li {
  font-size: 16px;
  line-height: 36px;
  color: var(--cretech-link-light, #877e9b);
}

.footer-widget__links li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__links li a:hover {
  color: #fff;
}

.footer-widget__contact {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-widget__contact li {
  position: relative;
  padding-left: 33px;
  color: var(--cretech-link-light, #877e9b);
  font-size: 16px;
  line-height: 36px;
}

.footer-widget__contact li>i {
  font-size: 16px;
  color: var(--cretech-secondary, #9c2ede);
  position: absolute;
  top: 11px;
  left: 0;
}

.footer-widget__contact li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__contact li a:hover {
  color: #fff;
}

.footer-widget__newsletter {
  margin-top: 21px;
}

.footer-widget__newsletter input[type=text],
.footer-widget__newsletter input[type=email] {
  width: 100%;
  padding-left: 20px;
  border: none;
  outline: none;
  background-color: #fff;
  color: var(--cretech-text, #797582);
  font-size: 14px;
  height: 50px;
  margin-bottom: 10px;
}

.footer-widget__newsletter__btn {
  padding: 10px 29px;
}

.footer-bottom {
  text-align: center;
  background-color: var(--cretech-bg-black, #0d0421);
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-bottom p {
  margin: 0;
  color: var(--cretech-link-light, #877e9b);
}

/***********************
* Blog
***********************/
.blog-card-one__image {
  position: relative;
  overflow: hidden;
}

.blog-card-one__image>img {
  max-width: 100%;
}

.blog-card-one__image>a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--cretech-black-rgb, 18, 6, 44), 0.8);
  -webkit-transition: -webkit-transform 500ms ease-out;
  transition: -webkit-transform 500ms ease-out;
  transition: transform 500ms ease-out;
  transition: transform 500ms ease-out, -webkit-transform 500ms ease-out;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.blog-card-one__image>a::after,
.blog-card-one__image>a::before {
  content: '';
  width: 25px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.blog-card-one__image>a::after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.blog-card-one:hover .blog-card-one__image>a {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.blog-card-one__content {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  padding: 30px;
  padding-bottom: 20px;
}

.blog-card-one__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-card-one__date {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-card-one__date i {
  color: var(--cretech-secondary, #9c2ede);
  margin-right: 5px;
}

.blog-card-one__category {
  text-transform: uppercase;
  background-color: var(--cretech-primary, #6a2dec);
  font-size: 10px;
  display: inline-block;
  font-weight: bold;
  letter-spacing: .1em;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 0 19px;
  margin-left: 27px;
}

.blog-card-one__category:hover {
  color: #fff;
  background-color: var(--cretech-black, #12062c);
}

.blog-card-one__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25em;
  margin: 0;
  color: var(--cretech-black, #12062c);
  letter-spacing: -0.04em;
  margin-top: 7px;
  margin-bottom: 13px;
}

.blog-card-one__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-card-one__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.blog-card-one__more {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-card-one__more i {
  color: var(--cretech-primary, #6a2dec);
  letter-spacing: -0.04em;
  margin-left: 7px;
}

.blog-card-one__more:hover {
  color: var(--cretech-black, #12062c);
}

.blog-card-one--home-two .blog-card-one__image>img {
  width: 100%;
}

.blog-card-one--home-two .blog-card-one__content {
  padding: 30px;
  position: relative;
}

.blog-card-one--home-two .blog-card-one__date {
  line-height: 1;
}

.blog-card-one--home-two .blog-card-one__title {
  margin-bottom: 5px;
  margin-top: 11px;
}

.blog-card-one--home-two .blog-card-one__category {
  position: absolute;
  top: -15px;
  right: 20px;
}

.blog-card-two {
  background-color: var(--cretech-black, #12062c);
  width: 100%;
  min-height: 370px;
  position: relative;
}

.blog-card-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}

.blog-card-two__bg::before {
  content: '';
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--cretech-black, #12062c)), to(rgba(var(--cretech-black-rgb, 18, 6, 44), 0)));
  background-image: linear-gradient(0deg, var(--cretech-black, #12062c) 0%, rgba(var(--cretech-black-rgb, 18, 6, 44), 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.blog-card-two__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.blog-card-two .blog-card-one__category {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
}

.blog-card-two .blog-card-one__date {
  color: #fff;
}

.blog-card-two .blog-card-one__title {
  color: #fff;
  margin: 0;
  max-width: 296px;
  margin-top: 7px;
}

.blog-card-two .blog-card-one__title a:hover {
  color: #fff;
}

@media (min-width: 1200px) {
  .comment-form {
    margin-top: -10px;
  }
}

.comment-form__title {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .comment-form__title {
    font-size: 36px;
    margin-bottom: 50px;
  }
}

.comment-form__form {
  background-color: #fff;
  padding: 0;
}

.comment-form__form input[type=text],
.comment-form__form input[type=email],
.comment-form__form textarea {
  background-color: var(--cretech-gray, #f9f6ff);
}

.blog-details__image {
  padding-bottom: 23px;
}

.blog-details__image img {
  max-width: 100%;
}

.blog-details__title {
  font-size: 30px;
}

.blog-details__content p {
  margin: 0;
}

.blog-details__content p+p {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .blog-details__content p+p {
    margin-top: 30px;
  }
}

.blog-details__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-top: 1px solid var(--cretech-border, #e1dbec);
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .blog-details__meta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .blog-details__meta {
    margin-top: 50px;
  }
}

.blog-details__tags {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-details__tags span {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
}

.blog-details__tags a {
  text-transform: uppercase;
  background-color: var(--cretech-primary, #6a2dec);
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
  letter-spacing: .1em;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 6.5px 18.5px;
  margin-left: 10px;
}

.blog-details__tags a:hover {
  color: #fff;
  background-color: var(--cretech-black, #12062c);
}

.blog-details__share {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .blog-details__share {
    margin-top: 0;
  }
}

.blog-details__share li {
  padding-left: 15px;
  padding-right: 15px;
}

.blog-details__share li a {
  font-size: 18px;
  color: var(--cretech-black, #12062c);
}

@media (min-width: 992px) {
  .blog-details__share li a {
    font-size: 20px;
  }
}

.author-one {
  background-color: var(--cretech-gray, #f9f6ff);
  padding: 40px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .author-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .author-one {
    padding: 60px;
  }
}

@media (min-width: 768px) {
  .author-one__content {
    padding-left: 40px;
  }
}

.author-one__title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-top: 30px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .author-one__title {
    margin-top: 7px;
  }
}

@media (min-width: 992px) {
  .author-one__title {
    margin-bottom: 18px;
  }
}

.author-one__text {
  margin: 0;
}

.comments-one {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .comments-one {
    margin-top: 50px;
  }
}

.comments-one__title {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .comments-one__title {
    font-size: 36px;
    margin-bottom: 50px;
  }
}

.comments-one__item {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--cretech-border, #e1dbec);
}

@media (min-width: 768px) {
  .comments-one__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .comments-one__item {
    margin-bottom: 60px;
    padding-bottom: 55px;
  }
}

.comments-one__item__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.comments-one__item__image img {
  max-width: 100%;
  border-radius: 50%;
}

.comments-one__item__content {
  position: relative;
}

@media (min-width: 768px) {
  .comments-one__item__content {
    padding-left: 45px;
  }
}

.comments-one__item__title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .comments-one__item__title {
    margin-top: 10px;
    margin-bottom: 26px;
  }
}

.comments-one__item__text {
  margin: 0;
  margin-bottom: 20px;
}

.comments-one__item__btn {
  font-size: 12px;
  padding: 4px 20px;
  background-color: var(--cretech-gray, #f9f6ff);
  color: var(--cretech-text, #797582);
}

@media (min-width: 992px) {
  .comments-one__item__btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.sidebar {
  margin-top: 80px;
}

@media (min-width: 992px) {
  .sidebar {
    margin-top: 0;
  }
}

.sidebar__item {
  background-color: var(--cretech-gray, #f9f6ff);
  padding: 50px;
}

.sidebar__item--search {
  padding: 0;
  background-color: var(--cretech-secondary, #9c2ede);
}

.sidebar__item+.sidebar__item {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin-top: -5px;
  margin-bottom: 25px;
}

.sidebar__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.sidebar__tags a {
  text-transform: uppercase;
  background-color: #fff;
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
  letter-spacing: .1em;
  color: var(--cretech-black, #12062c);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 6.5px 18.5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.sidebar__tags a:hover {
  color: #fff;
  background-color: var(--cretech-primary, #6a2dec);
}

.sidebar__category {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -15px;
  margin-bottom: -15px;
}

.sidebar__category li {
  font-size: 16px;
  line-height: 24px;
  color: var(--cretech-text, #797582);
}

.sidebar__category li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9.5px 20px;
}

.sidebar__category li a:hover {
  background-color: #fff;
}

.sidebar__category li a::after {
  content: '\e91b';
  font-family: 'icomoon';
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
}

.sidebar__category li a:hover {
  color: var(--cretech-black, #12062c);
}

.sidebar__category li a:hover::after {
  opacity: 1;
}

.sidebar__posts {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: -20px;
}

.sidebar__posts li {
  padding: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar__posts li:hover {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
}

.sidebar__posts__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.sidebar__posts__content {
  margin-top: -5px;
  padding-left: 15px;
}

.sidebar__posts__title {
  margin: 0;
  font-size: 18px;
  line-height: 1.444em;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
}

.sidebar__posts__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__posts__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.sidebar__posts__date {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}

.sidebar__posts__date i {
  margin-right: 5px;
  color: var(--cretech-secondary, #9c2ede);
}

.sidebar__search {
  position: relative;
}

.sidebar__search input[type=text],
.sidebar__search input[type=search] {
  width: 100%;
  display: block;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  height: 84px;
  padding-left: 50px;
  font-size: 16px;
  font-weight: 500;
}

.sidebar__search button[type=submit] {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #fff;
  padding: 0;
  font-size: 22px;
  position: absolute;
  top: 50%;
  right: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar__search button[type=submit] span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar__search button[type=submit]::before,
.sidebar__search button[type=submit]::after {
  display: none;
}

.sidebar__item--cta {
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
}

.sidebar__cta {
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  mix-blend-mode: luminosity;
  position: relative;
  text-align: center;
  background-size: cover;
}

.sidebar__cta::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  opacity: 0.93;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sidebar__cta * {
  position: relative;
  z-index: 10;
}

.sidebar__cta__icon {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--cretech-black, #12062c);
  font-size: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 35px;
}

.sidebar__cta:hover .sidebar__cta__icon {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.sidebar__cta__title {
  color: #fff;
  margin: 0;
  margin-bottom: 33px;
}

.sidebar__cta__text {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 23px;
}

.sidebar__cta__text a {
  font-size: 18px;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__cta__text a:hover {
  color: #fff;
  background-size: 100% 1px;
}

.sidebar__item--btn {
  padding: 0;
}

.sidebar__btn {
  text-align: center;
  width: 100%;
  background-color: var(--cretech-black, #12062c);
}

.blog-home-two .section-title {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .blog-home-two__top {
    margin-bottom: 48px;
  }
}

.blog-home-two__top__text {
  margin-top: 30px;
  margin-bottom: 43px;
}

@media (min-width: 992px) {
  .blog-home-two__top__text {
    padding-left: 26px;
    margin-top: 35px;
    margin-bottom: 0;
  }
}

.blog-one--home-three {
  position: relative;
}

@media (min-width: 1200px) {
  .blog-one--home-three {
    padding-top: 240px;
  }
}

.blog-one--home-three::before {
  content: '';
  width: 100%;
  height: 699px;
  background-size: cover;
  background-color: var(--cretech-gray, #f9f6ff);
  background-image: url(../images/shapes/blog-home-three-bg-1-1.png);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

@media (min-width: 1200px) {
  .blog-one--home-three::before {
    display: block;
  }
}

.blog-one--home-three .container {
  position: relative;
}

/***********************
* Project
***********************/
.project-card-one {
  position: relative;
  overflow: hidden;
}

.project-card-one__image img {
  width: 100%;
}

.project-card-one__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 30px;
  padding-right: 50px;
}

.project-card-one__content__inner {
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  position: relative;
  width: 100%;
  padding: 30px;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-perspective: 400px;
  perspective: 400px;
  visibility: hidden;
  -webkit-transition: visibility 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  transition: visibility 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, visibility 500ms ease-in-out;
  transition: transform 500ms ease-in-out, visibility 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.project-card-one:hover .project-card-one__content__inner {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  visibility: visible;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.project-card-one__text {
  margin: 0;
  color: #e5d4ff;
  font-size: 14px;
  font-weight: 500;
  margin-top: -10px;
}

.project-card-one__title {
  margin: 0;
  font-size: 24px;
  line-height: 1.25em;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.025em;
  max-width: 165px;
  margin-top: -5px;
}

.project-card-one__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-card-one__title a:hover {
  color: #fff;
  background-size: 100% 2px;
}

.project-card-one__more {
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 160px;
}

.project-card-one__more:hover {
  background-color: #fff;
  color: var(--cretech-black, #12062c);
}

.project-nav__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid var(--cretech-border, #e1dbec);
  border-bottom: 1px solid var(--cretech-border, #e1dbec);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

.project-nav__inner a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--cretech-black, #12062c);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .1em;
}

.project-nav__inner a:hover {
  color: var(--cretech-primary, #6a2dec);
}

.project-nav__inner a i {
  margin-right: 10px;
}

.project-nav__inner a:nth-child(2) i {
  margin-right: 0;
  margin-left: 10px;
}

.project-details__title {
  margin: 0;
  color: var(--cretech-black, #12062c);
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .project-details__title {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .project-details__title {
    font-size: 40px;
  }
}

.project-details__content p {
  margin: 0;
}

.project-details__content p+p {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .project-details__content p+p {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .project-details__content p+p {
    margin-top: 45px;
  }
}

.project-details__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: -10px;
}

@media (min-width: 992px) {
  .project-details__list {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .project-details__list {
    margin-top: 38px;
  }
}

.project-details__list li {
  position: relative;
  color: var(--cretech-black, #12062c);
  font-size: 18px;
  line-height: 2.11em;
  padding-left: 26px;
}

.project-details__list li>i {
  color: var(--cretech-primary, #6a2dec);
  font-size: 16px;
  position: absolute;
  top: 12px;
  left: 0;
}

.project-details__image img {
  max-width: 100%;
}

.project-details__info {
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 768px) {
  .project-details__info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .project-details__info {
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -40px;
    padding-top: 49px;
    padding-bottom: 43px;
  }
}

@media (min-width: 992px) {
  .project-details__info {
    margin-left: 40px;
    margin-right: 40px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.project-details__info__item+.project-details__info__item {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .project-details__info__item+.project-details__info__item {
    margin-top: 0;
  }
}

.project-details__info__title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}

.project-details__info__text {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.project-details__info__text a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__info__text a:hover {
  color: var(--cretech-black, #12062c);
}

.project-details__info__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.project-details__info__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
}

.project-details__info__social a:hover {
  color: #fff;
  background-color: var(--cretech-black, #12062c);
}

.project-details__info__social a+a {
  margin-left: 10px;
}

.project-one__top {
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .project-one__top {
    margin-bottom: 60px;
  }
}

.project-one__top .section-title {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .project-one__top .section-title {
    padding-bottom: 0;
  }
}

.project-one__top__text {
  margin: 0;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .project-one__top__text {
    text-align: right;
    margin-bottom: 0;
    margin-top: 11px;
  }
}

@media (min-width: 992px) {
  .project-one__top__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.project-one__top__btn {
  background-color: var(--cretech-gray, #f9f6ff);
  color: var(--cretech-black, #12062c);
}

@media (min-width: 1200px) {
  .project-one__carousel.thm-owl__carousel--with-shadow .owl-item.active+.owl-item {
    opacity: 1;
    visibility: visible;
  }
}

.project-one__carousel .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 40px;
}

@media (min-width: 992px) {
  .project-one__carousel .owl-nav {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  .project-one__carousel .owl-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: absolute;
    bottom: 0;
    right: calc(100% + 20px);
    margin-top: 0;
  }
}

.project-one__carousel .owl-nav [class*=owl-] {
  margin: 0;
  width: 51px;
  height: 51px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
}

.project-one__carousel .owl-nav [class*=owl-]+[class*=owl-] {
  margin-left: 10px;
}

@media (min-width: 1200px) {
  .project-one__carousel .owl-nav [class*=owl-]+[class*=owl-] {
    margin-left: 0;
    margin-top: 10px;
  }
}

.project-one__carousel .owl-nav [class*=owl-]:hover {
  background-color: var(--cretech-primary, #6a2dec);
}

.project-one__carousel .owl-nav [class*=owl-]:hover span {
  color: #fff;
}

.project-one__carousel .owl-nav [class*=owl-] span {
  color: var(--cretech-black, #12062c);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 20px;
}

.project-two .container-fluid {
  width: 100%;
  max-width: 1630px;
}

@media (min-width: 992px) {
  .project-two .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.project-three .container-fluid {
  width: 100%;
  max-width: 1530px;
}

@media (min-width: 992px) {
  .project-three .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.project-card-two {
  background-color: var(--cretech-black, #12062c);
  min-height: 400px;
  position: relative;
  width: 100%;
}

@media (min-width: 992px) {
  .project-card-two {
    width: 290px;
  }
}

@media (min-width: 992px) {
  .project-card-two--large {
    width: 570px;
  }
}

.project-card-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}

.project-card-two__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 30px;
  overflow: hidden;
}

.project-card-two__category {
  background-color: var(--cretech-primary, #6a2dec);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .1em;
  line-height: 1em;
  color: #fff;
  padding: 16px 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
}

.project-card-two:hover .project-card-two__category {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.project-card-two__title {
  margin: 0;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  padding: 18px 44px 18px 25px;
  line-height: 1.2em;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.project-card-two__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-card-two__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.project-card-two:hover .project-card-two__title {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/***********************
* Search Popup
***********************/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.search-popup.active {
  opacity: 1;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.9;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-130%);
  transform: translateY(-130%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.search-popup.active .search-popup__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--cretech-primary, #6a2dec);
  border: 0;
}

.search-popup__content .thm-btn i {
  background-color: transparent;
  margin-left: 0;
}

.search-popup__content .thm-btn:hover {
  background-color: var(--cretech-secondary, #9c2ede);
}

/***********************
* Services
***********************/
@media (min-width: 992px) {
  .service-details--page .sidebar-column {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.service-details__image img {
  max-width: 100%;
}

.service-details__title {
  margin: 0;
  color: var(--cretech-black, #12062c);
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-top: 30px;
  margin-bottom: 20px;
}

.service-details__content {
  margin-bottom: 30px;
}

.service-details__content p {
  margin: 0;
  margin-bottom: 25px;
}

.service-details__content h4 {
  margin: 0;
  font-weight: bold;
  color: var(--cretech-secondary, #9c2ede);
  font-size: 22px;
  line-height: 1.36em;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .service-details__content h4 {
    margin-bottom: 32px;
  }
}

.service-details__sub-image {
  margin-top: 30px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .service-details__sub-image {
    margin-top: 0;
  }
}

.service-details__box {
  background-color: var(--cretech-gray, #f9f6ff);
  padding: 40px;
}

@media (min-width: 992px) {
  .service-details__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .service-details__box__content {
    padding-left: 34px;
  }
}

@media (min-width: 768px) {
  .service-details__box-wrapper {
    margin-top: 8px;
    margin-bottom: 60px;
  }
}

.service-details__box__icon {
  font-size: 49px;
  color: var(--cretech-black, #12062c);
}

.service-details__box__title {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  color: var(--cretech-primary, #6a2dec);
  margin-bottom: 14px;
  margin-top: 18px;
}

@media (min-width: 992px) {
  .service-details__box__title {
    margin-top: -5px;
  }
}

.service-details__box__text {
  margin: 0;
  margin-bottom: -7px;
}

.service-details__caption__title {
  margin: 0;
  font-size: 24px;
  line-height: 1.4167em;
  color: var(--cretech-black, #12062c);
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-top: -8px;
  margin-bottom: 31px;
}

.service-details__caption__text {
  margin: 0;
  font-size: 20px;
  line-height: 1.7em;
  color: var(--cretech-primary, #6a2dec);
  margin-bottom: 35px;
}

.service-details__caption__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: -10px;
}

.service-details__caption__list li {
  position: relative;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: var(--cretech-text, #797582);
  padding-left: 33px;
}

.service-details__caption__list li>i {
  color: var(--cretech-secondary, #9c2ede);
  font-size: 16px;
  position: absolute;
  top: 8px;
  left: 0;
}

.service-details__accrodion {
  margin-top: 60px;
}

.service-details__accrodion .accrodion {
  border: 1px solid var(--cretech-border, #e1dbec);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  background-color: #fff;
}

.service-details__accrodion .accrodion.active {
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.07);
}

.service-details__accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.service-details__accrodion .accrodion-title h4 {
  font-size: 18px;
  color: var(--cretech-black, #12062c);
  margin: 0;
  letter-spacing: -0.05em;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 26px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-details__accrodion .accrodion.active .accrodion-title h4 {
  color: var(--cretech-primary, #6a2dec);
}

.service-details__accrodion .accrodion-icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.service-details__accrodion .accrodion-icon::before,
.service-details__accrodion .accrodion-icon::after {
  content: '';
  background-color: var(--cretech-black, #12062c);
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.service-details__accrodion .accrodion-icon::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.service-details__accrodion .accrodion.active .accrodion-icon::after {
  opacity: 0;
}

.service-details__accrodion .accrodion-content .inner {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 33px;
}

.service-details__accrodion .accrodion-content .inner p {
  margin: 0;
}

@media (min-width: 1200px) {
  .service-one {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1440px) {
  .service-one {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1580px) {
  .service-one {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.service-one__carousel .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 40px;
}

.service-one__carousel .owl-nav button {
  font-size: 14px;
  width: 40px;
  height: 40px;
  background-color: var(--cretech-gray, #f9f6ff) !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-one__carousel .owl-nav button+button {
  margin-left: 10px;
}

.service-one__carousel .owl-nav button:hover {
  color: #fff;
  background-color: var(--cretech-primary, #6a2dec) !important;
}

.service-card-one {
  padding: 40px;
  position: relative;
}

.service-card-one::before {
  content: '';
  width: 70px;
  background-color: #fff;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

@media (min-width: 1700px) {
  .service-card-one::before {
    display: block;
  }
}

@media (min-width: 768px) {
  .service-card-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 1700px) {
  .service-card-one {
    padding-left: 0;
  }
}

.service-card-one__image {
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: var(--cretech-black, #12062c);
}

.service-card-one__image img {
  max-width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
}

.service-card-one:hover .service-card-one__image img {
  opacity: 0.7;
}

.service-card-one__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -31px;
  width: 62px;
  height: 62px;
  background-image: linear-gradient(30deg, #ab32f2 0%, var(--cretech-primary, #6a2dec) 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.05em;
}

.service-card-one__content {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .service-card-one__content {
    padding-top: 0;
    padding-left: 40px;
  }
}

.service-card-one__title {
  color: var(--cretech-black, #12062c);
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: var(--cretech-black, #12062c);
  letter-spacing: -0.05em;
  line-height: 1.154em;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .service-card-one__title {
    margin-top: -13px;
  }
}

@media (min-width: 992px) {
  .service-card-one__title {
    font-size: 26px;
    margin-bottom: 25px;
  }
}

.service-card-one__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-card-one__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.service-card-one__text {
  margin: 0;
  margin-bottom: -15px;
}

.service-card-two {
  position: relative;
  padding: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: var(--cretech-gray, #f9f6ff);
}

.service-card-two::before {
  content: '';
  background-image: url(../images/shapes/service-card-two-bg-1-1.png);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  background-position: center center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-card-two:hover {
  background-color: rgba(var(--cretech-primary-rgb, 106, 46, 237), 1);
}

.service-card-two:hover::before {
  opacity: 0.4;
}

.service-card-two__title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  color: var(--cretech-black, #12062c);
  letter-spacing: -0.05em;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 19px;
}

@media (min-width: 768px) {
  .service-card-two__title {
    font-size: 24px;
  }
}

.service-card-two__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-card-two__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.service-card-two:hover .service-card-two__title a {
  color: #fff;
}

.service-card-two__text {
  position: relative;
  margin: 0;
  margin-bottom: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-card-two:hover .service-card-two__text {
  color: #d9c7ff;
}

.service-card-two__icon {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: -17px;
  margin-bottom: -10px;
  -webkit-box-shadow: 0px 15px 21px 0px rgba(var(--cretech-primary-rgb, 106, 46, 237), 0.16);
  box-shadow: 0px 15px 21px 0px rgba(var(--cretech-primary-rgb, 106, 46, 237), 0.16);
  border-radius: 50%;
}

.service-card-two__icon i {
  color: var(--cretech-primary, #6a2dec);
  font-size: 64px;
}

.service-three__block {
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .service-three__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .service-three__block .section-title {
    padding-bottom: 0;
  }
}

.service-three__block__text {
  margin-top: -20px;
  color: var(--cretech-link-light, #877e9b);
}

@media (min-width: 768px) {
  .service-three__block__text {
    max-width: 460px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .service-three__block__text {
    margin-top: 33px;
  }
}

.service-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

@media (min-width: 768px) {
  .service-three__list {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .service-three__list {
    -ms-grid-columns: (1fr)[6];
    grid-template-columns: repeat(6, 1fr);
  }
}

.service-three__list__item {
  padding-top: 30px;
  padding-bottom: 22px;
  border: 1px dashed #ab32f2;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-three__list__item:hover {
  background-color: #ab32f2;
}

.service-three__list__icon {
  font-size: 48px;
  color: #fff;
}

.service-three__list__title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
}

.service-three__list__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-three__list__title a:hover {
  color: #fff;
  background-size: 100% 2px;
}

@media (min-width: 576px) {
  .service-four__carousel .owl-item:nth-of-type(even) {
    margin-top: 30px;
  }
}

.service-card-three {
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 32px;
  padding-top: 48px;
}

.service-card-three::before {
  content: '';
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.service-card-three:hover::before {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.service-card-three__icon {
  position: relative;
  display: inline-block;
}

.service-card-three__icon::before {
  content: '';
  width: 70px;
  height: 70px;
  background-color: var(--cretech-gray, #f9f6ff);
  position: absolute;
  top: -4px;
  left: -31px;
  border-radius: 50%;
  -webkit-transition: all 800ms ease;
  transition: all 800ms ease;
  opacity: 1;
}

.service-card-three__icon i {
  position: relative;
  font-size: 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--cretech-primary, #6a2dec);
}

.service-card-three:hover .service-card-three__icon::before {
  left: -15px;
  background-color: rgba(255, 255, 255, 0.1);
}

.service-card-three:hover .service-card-three__icon i {
  color: #fff;
}

.service-card-three__content {
  position: relative;
}

.service-card-three__title {
  color: var(--cretech-black, #12062c);
  font-size: 20px;
  line-height: 1.2em;
  letter-spacing: -0.025em;
  margin-top: 21px;
}

.service-card-three__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-card-three__title a:hover {
  color: #fff;
  background-size: 100% 2px;
}

.service-card-three:hover .service-card-three__title a {
  color: #fff;
}

.service-card-three__text {
  font-size: 14px;
  line-height: 1.714em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 175px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.service-card-three:hover .service-card-three__text {
  color: #fff;
}

/***********************
* Team
***********************/
.team-card-one__image {
  position: relative;
}

.team-card-one__image img {
  width: 100%;
}

.team-card-one__image::before {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../images/shapes/team-shine-1-1.png);
  background-size: cover;
}

.team-card-one__social {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.team-card-one__social__links {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.team-card-one__social__links li a {
  color: var(--cretech-black, #12062c);
  font-size: 16px;
}

.team-card-one__social:hover .team-card-one__social__links {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.team-card-one__social__icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-card-one__social__icon i {
  color: var(--cretech-primary, #6a2dec);
  font-size: 24px;
}

.team-card-one__social:hover .team-card-one__social__icon {
  background-color: var(--cretech-primary, #6a2dec);
}

.team-card-one__social:hover .team-card-one__social__icon i {
  color: #fff;
}

.team-card-one__content {
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  -webkit-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}

.team-card-one__content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
  width: 6px;
  height: 44px;
  background-color: var(--cretech-secondary, #9c2ede);
}

.team-card-one:hover .team-card-one__content {
  background-color: var(--cretech-secondary, #9c2ede);
}

.team-card-one__title {
  margin: 0;
  color: var(--cretech-black, #12062c);
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-top: -5px;
}

.team-card-one__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-card-one__title a:hover {
  color: #fff;
  background-size: 100% 1px;
}

.team-card-one:hover .team-card-one__title a {
  color: #fff;
}

.team-card-one__designation {
  letter-spacing: -0.05em;
  margin: 0;
  color: var(--cretech-primary, #6a2dec);
  line-height: 1;
  margin-top: 2px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-card-one:hover .team-card-one__designation {
  color: #fff;
}

/***********************
* Client Carousel
***********************/
.client-carousel {
  padding-top: 80px;
  padding-bottom: 80px;
}

.client-carousel .item {
  padding: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.client-carousel .item:hover {
  background-color: var(--cretech-gray, #f9f6ff);
}

.client-carousel img {
  -webkit-transition: 500ms;
  transition: 500ms;
  opacity: 0.3;
  max-width: 100%;
}

.client-carousel img:hover {
  opacity: 0.6;
}

.client-carousel--two {
  padding: 0;
}

.client-carousel--two .owl-nav {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding-left: 26px;
  padding-right: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.client-carousel--two .owl-nav button {
  border: none;
  outline: none;
  width: 26px;
  height: 26px;
  background-color: var(--cretech-gray, #f9f6ff) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--cretech-black, #12062c);
  font-size: 12px !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.client-carousel--two .owl-nav button+button {
  margin-left: 5px;
}

.client-carousel--two .owl-nav button:hover {
  background-color: var(--cretech-primary, #6a2dec) !important;
  color: #fff;
}

.client-carousel--two .owl-stage-outer {
  padding-top: 80px;
  padding-bottom: 80px;
  border-top: 1px solid var(--cretech-border, #e1dbec);
}

.client-carousel--home-two {
  border-top: 1px solid var(--cretech-border, #e1dbec);
}

.client-carousel--home-two .owl-stage-outer {
  border: 0;
}

/***********************
* About
***********************/
.about-one__image {
  position: relative;
}

@media (min-width: 1200px) {
  .about-one__image {
    margin-top: -35px;
    margin-bottom: -50px;
  }
}

.about-one__image::before {
  content: '';
  width: 490px;
  height: 490px;
  background-color: var(--cretech-gray, #f9f6ff);
  position: absolute;
  top: 50px;
  left: -90px;
  border-radius: 50%;
  display: none;
}

@media (min-width: 992px) {
  .about-one__image::before {
    display: block;
  }
}

.about-one__image::after {
  content: '';
  width: 208px;
  height: 208px;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  position: absolute;
  bottom: 50px;
  left: 120px;
  border-radius: 50%;
  display: none;
}

@media (min-width: 992px) {
  .about-one__image::after {
    display: block;
  }
}

.about-one__image img {
  max-width: 100%;
  position: relative;
  z-index: 10;
}

@media (min-width: 1200px) {
  .about-one__image img {
    margin-left: 70px;
  }
}

.about-one__text {
  margin-top: -20px;
}

.about-one__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .about-one__list {
    margin-top: 37px;
    margin-bottom: 40px;
  }
}

.about-one__list li {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: var(--cretech-black, #12062c);
  line-height: 1.7em;
  padding-left: 30px;
}

.about-one__list li+li {
  margin-top: 5px;
}

.about-one__list li>i {
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 16px;
  color: var(--cretech-secondary, #9c2ede);
}

@media (min-width: 1200px) {
  .about-one__content {
    padding-left: 70px;
  }
}

.about-two {
  background-image: url(../images/shapes/about-two-bg-1-1.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.about-two__image {
  display: inline-block;
  position: relative;
}

@media (min-width: 1200px) {
  .about-two__image {
    right: 20px;
    margin-top: 15px;
  }
}

.about-two__image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  -webkit-transform: rotate(3deg);
  transform: rotate(3deg);
}

.about-two__image img {
  max-width: 100%;
  position: relative;
}

@media (min-width: 1200px) {
  .about-two__image img {
    max-width: none;
  }
}

.about-two .section-title {
  padding-bottom: 30px;
}

.about-two__list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: aboutTwoNumber;
  margin-bottom: 50px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .about-two__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}

.about-two__list li {
  counter-increment: aboutTwoNumber;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.05em;
  line-height: 1.2em;
  color: var(--cretech-black, #12062c);
}

.about-two__list li+li {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .about-two__list li+li {
    margin-top: 0;
  }
}

.about-two__list li::before {
  content: counters(aboutTwoNumber, ".", decimal-leading-zero);
  width: 58px;
  height: 58px;
  background-color: var(--cretech-secondary, #9c2ede);
  margin-right: 20px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 1200px) {
  .about-two__content {
    padding-right: 100px;
  }
}

.about-three {
  position: relative;
}

.about-three__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 685px;
  background-repeat: no-repeat;
  background-position: top right;
}

.about-three::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/shapes/about-three-bg-1-1.png);
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 20;
  display: none;
}

@media (min-width: 1200px) {
  .about-three::before {
    display: block;
  }
}

.about-three__image {
  position: relative;
  display: inline-block;
}

.about-three__image::before {
  position: absolute;
  content: '';
  width: 10000px;
  right: 51%;
  background-color: var(--cretech-primary, #6a2dec);
  top: -120px;
  bottom: -120px;
  display: none;
}

@media (min-width: 992px) {
  .about-three__image::before {
    display: block;
  }
}

.about-three__image::after {
  content: '';
  background-color: var(--cretech-secondary, #9c2ede);
  position: absolute;
  right: calc(51% - 39px);
  bottom: 100%;
  width: 19px;
  height: 90px;
}

.about-three__image img {
  position: relative;
  max-width: 100%;
}

.about-three--home-one .about-three__image::before {
  bottom: 0;
}

.about-three__text {
  margin-top: -20px;
  margin-bottom: 35px;
}

.about-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .about-three__list__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.about-three__list__item+.about-three__list__item {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .about-three__list__item+.about-three__list__item {
    margin-top: 35px;
  }
}

.about-three__list__icon {
  font-size: 52px;
  color: #ffffff;
  margin-bottom: 20px;
  background-color: var(--cretech-black, #12062c);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90px;
  height: 90px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .about-three__list__icon {
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: 4px;
  }
}

.about-three__list__title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: var(--cretech-primary, #6a2dec);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .about-three__list__title {
    margin-bottom: 0px;
  }
}

.about-three__list__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-three__list__title a:hover {
  color: var(--cretech-black, #12062c);
}

.about-three__list__text {
  margin: 0;
}

@media (min-width: 1200px) {
  .about-four__content {
    margin-left: 70px;
  }
}

.about-four__image {
  display: inline-block;
  position: relative;
}

.about-four__image::before {
  content: '';
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 30px;
  right: -30px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--cretech-secondary, #9c2ede)), to(var(--cretech-primary, #6a2dec)));
  background-image: linear-gradient(0deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  display: none;
}

@media (min-width: 768px) {
  .about-four__image::before {
    display: block;
  }
}

@media (min-width: 992px) {
  .about-four__image::before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .about-four__image::before {
    display: block;
  }
}

.about-four__image img {
  position: relative;
  max-width: 100%;
}

.about-four__text {
  margin-top: -20px;
  margin-bottom: 40px;
}

.about-four__meta {
  border-top: 1px solid var(--cretech-border, #e1dbec);
  padding-top: 30px;
}

@media (min-width: 768px) {
  .about-four__meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.about-four__author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .about-four__author {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .about-four__author {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .about-four__author {
    margin-bottom: 0;
  }
}

.about-four__author img {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.about-four__author__title {
  margin: 0;
  color: var(--cretech-black, #12062c);
  letter-spacing: -0.025em;
  font-size: 20px;
  font-weight: bold;
}

.about-four__author__designation {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--cretech-primary, #6a2dec);
  margin: 0;
  line-height: 1em;
  margin-top: 5px;
  font-weight: bold;
}

.about-five__images {
  position: relative;
  display: inline-block;
}

.about-five__images__shape {
  width: 503px;
  height: 366px;
  background-image: url(../images/shapes/about-five-1-1.png);
  position: absolute;
  bottom: -183px;
  left: -251.5px;
  display: none;
}

@media (min-width: 1200px) {
  .about-five__images__shape {
    display: block;
  }
}

.about-five__images img {
  max-width: 100%;
}

.about-five__images img:nth-of-type(2) {
  display: block;
  margin-left: auto;
  margin-top: -80px;
  position: relative;
}

@media (min-width: 992px) {
  .about-five__images img:nth-of-type(2) {
    margin: 0;
    position: absolute;
    bottom: -25px;
    right: 25px;
  }
}

@media (min-width: 1200px) {
  .about-five__images img:nth-of-type(2) {
    right: -110px;
  }
}

.about-five__images__caption {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 280px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  border-right: 10px solid var(--cretech-primary, #6a2dec);
  padding-left: 44px;
  padding-right: 30px;
  padding-top: 29px;
  padding-bottom: 17px;
  border-radius: 55px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (min-width: 992px) {
  .about-five__images__caption {
    bottom: -55px;
    left: 90px;
  }
}

.about-five__images__caption span {
  font-weight: bold;
  line-height: 1;
  display: block;
  font-size: 72px;
  color: var(--cretech-secondary, #9c2ede);
  margin-right: 20px;
  margin-top: -8px;
  letter-spacing: -0.05em;
}

.about-five__video {
  width: 80px;
  height: 80px;
  background-color: var(--cretech-primary, #6a2dec);
  -webkit-box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
}

@media (min-width: 992px) {
  .about-five__video {
    top: 30px;
    right: 30px;
    -webkit-transform: translate(0);
    transform: translate(0);
    left: auto;
  }
}

@media (min-width: 1200px) {
  .about-five__video {
    top: 80px;
    right: -40px;
  }
}

.about-five__video::before,
.about-five__video::after {
  content: '';
  position: absolute;
  border: 1px solid var(--cretech-border, #e1dbec);
  z-index: -1;
}

.about-five__video::before {
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  -webkit-animation: videoBtnAnim 3s ease-in-out infinite;
  animation: videoBtnAnim 3s ease-in-out infinite;
}

.about-five__video::after {
  width: 140px;
  height: 140px;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  -webkit-animation: videoBtnAnim 5s ease-in-out infinite;
  animation: videoBtnAnim 5s ease-in-out infinite;
}

.about-five__video:hover {
  background-color: var(--cretech-black, #12062c);
  color: #fff;
}

.about-five__video a {
  color: inherit;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.about-five .section-title {
  padding-bottom: 30px;
}

.about-five__text+.about-five__text {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .about-five__text+.about-five__text {
    margin-top: 30px;
  }
}

.about-five__box {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .about-five__box {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .about-five__box {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .about-five__box {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1200px) {
  .about-five__box {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

.about-five__box__item {
  padding: 26px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-five__box__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: var(--cretech-black, #12062c);
  font-size: 48px;
  margin-right: 20px;
}

.about-five__box__title {
  color: var(--cretech-primary, #6a2dec);
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2em;
  margin-top: -3px;
}

.about-five__box__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-five__box__title a:hover {
  color: var(--cretech-primary, #6a2dec);
  background-size: 100% 2px;
}

.about-five__box__text {
  margin: 0;
  margin-bottom: -3px;
}

.about-six {
  background-image: url(../images/shapes/about-six-bg-1-1.png);
  background-repeat: no-repeat;
  background-position: right 50%;
}

@media (min-width: 1200px) {
  .about-six .container>.row>[class*=col-]:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.about-six__images {
  position: relative;
  display: inline-block;
}

.about-six__images::before {
  content: '';
  background-image: url(../images/shapes/about-six-dots-1-1.png);
  width: 370px;
  height: 370px;
  position: absolute;
  bottom: 0;
  left: 100%;
  margin-left: -185px;
  display: none;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media (min-width: 992px) {
  .about-six__images::before {
    display: block;
  }
}

@media (min-width: 1200px) {
  .about-six__images::before {
    left: 0;
    bottom: 63px;
    margin-left: -147px;
  }
}

.about-six__images::after {
  content: '';
  width: 30px;
  height: 110px;
  background-color: var(--cretech-secondary, #9c2ede);
  right: calc(100% + 30px);
  top: 0;
  position: absolute;
}

.about-six__images img {
  position: relative;
  max-width: 100%;
}

.about-six__images img:nth-of-type(2) {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .about-six__images img:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .about-six__images img:nth-of-type(2) {
    right: auto;
    left: -183px;
  }
}

.about-six__text {
  margin-bottom: 43px;
  margin-top: -20px;
}

.about-six__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
}

.about-six__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--cretech-black, #12062c);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .1em;
  margin-left: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 768px) {
  .about-six__link {
    margin-left: 30px;
  }
}

.about-six__link:hover {
  color: var(--cretech-primary, #6a2dec);
}

.about-six__link i {
  margin-left: 5px;
}

.about-six__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
}

@media (min-width: 768px) {
  .about-six__list {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .about-six__list {
    padding-right: 140px;
  }
}

.about-six__list__item {
  padding: 30px 40px;
  background-color: var(--cretech-gray, #f9f6ff);
  border-top: 4px solid var(--cretech-primary, #6a2dec);
  padding-top: 26px;
}

.about-six__list__icon {
  font-size: 24px;
  color: var(--cretech-secondary, #9c2ede);
}

.about-six__list__title {
  margin: 0;
  color: var(--cretech-black, #12062c);
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1.33em;
  margin-top: 10px;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .about-six__list__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .about-six__list__title {
    font-size: 48px;
  }
}

.about-six__list__text {
  letter-spacing: -0.025em;
  font-weight: 400;
  margin-bottom: -10px;
}

.about-seven .section-title {
  padding-bottom: 30px;
}

.about-seven__text {
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .about-seven__text {
    padding-right: 44px;
  }
}

.about-seven__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
}

@media (min-width: 576px) {
  .about-seven__list {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .about-seven__list {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .about-seven__list {
    padding-left: 7px;
  }
}

.about-seven__list__item {
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-top: 36px;
  padding-bottom: 29px;
}

.about-seven__list__item:hover {
  background-color: var(--cretech-black, #12062c);
}

.about-seven__list__item:hover::before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.about-seven__list__item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-image: url(../images/shapes/about-seven-list-bg-1-1.png);
  opacity: 0;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  background-repeat: no-repeat;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.about-seven__list__item::after {
  content: '';
  width: 16px;
  height: 16px;
  background-color: var(--cretech-primary, #6a2dec);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.about-seven__list__item:hover::after {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.about-seven__list__icon {
  position: relative;
  font-size: 64px;
  color: var(--cretech-primary, #6a2dec);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-seven__list__item:hover .about-seven__list__icon {
  color: #fff;
}

.about-seven__list__title {
  position: relative;
  margin: 0;
  font-size: 20px;
  line-height: 1.2em;
  color: var(--cretech-black, #12062c);
  margin-top: 17px;
  letter-spacing: -0.025em;
}

.about-seven__list__title a {
  background: -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-seven__list__title a:hover {
  color: #fff;
  background-size: 100% 2px;
}

.about-seven__list__item:hover .about-seven__list__title a {
  color: #fff;
}

/***********************
* CTA
***********************/
.cta-one {
  position: relative;
  z-index: 10;
}

.cta-one .container {
  position: relative;
}

.cta-one .container::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 45px;
  right: 45px;
  top: 0;
  background-color: #fff;
  opacity: 0.1;
}

.cta-one__circle {
  width: 336px;
  height: 336px;
  border: 70px solid #fff;
  opacity: 0.08;
  position: absolute;
  top: -110px;
  left: -110px;
  border-radius: 50%;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.cta-one__inner {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ab32f2), to(var(--cretech-primary, #6a2dec)));
  background-image: linear-gradient(0deg, #ab32f2 0%, var(--cretech-primary, #6a2dec) 100%);
  position: relative;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 80px;
  padding-bottom: 70px;
}

.cta-one__inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/shapes/cta-one-bg-1-1.png);
  background-repeat: no-repeat;
  background-position: top right;
}

@media (min-width: 992px) {
  .cta-one__inner {
    padding-top: 100px;
    padding-bottom: 90px;
  }
}

.cta-one .section-title {
  position: relative;
  padding-bottom: 40px;
}

.cta-one .section-title__text {
  color: #d9c7ff;
}

.cta-one__btn {
  position: relative;
  padding: 15px 53px;
}

.cta-one--home-three {
  text-align: center;
}

@media (min-width: 1200px) {
  .cta-one--home-three__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .cta-one--home-three {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .cta-one--home-three .cta-one__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 68px;
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
  }
}

.cta-one--home-three .section-title .section-title__title {
  margin-top: 0;
}

@media (min-width: 1200px) {
  .cta-one--home-three .section-title {
    padding-bottom: 0;
  }
}

.cta-one--home-three .cta-one__inner::before {
  background-image: url(../images/shapes/cta-one-bg-1-2.png);
}

.cta-two {
  position: relative;
  text-align: center;
}

.cta-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cretech-black, #12062c);
  mix-blend-mode: luminosity;
}

.cta-two__bg>div>div {
  opacity: 1;
}

.cta-two__bg::before {
  content: '';
  background-image: url(../images/shapes/cta-two-bg-1-1.png);
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: soft-light;
}

.cta-two .container {
  position: relative;
}

.cta-two__title {
  margin: 0;
  color: #fff;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .cta-two__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .cta-two__title {
    font-size: 48px;
    line-height: 1.2em;
    max-width: 662px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
  }
}

.cta-two__title span {
  position: relative;
  background-image: url(../images/shapes/cta-two-title-s-1.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
}

@media (min-width: 992px) {
  .cta-two__btn {
    margin-bottom: -10px;
  }
}

/***********************
* Testimonials
***********************/
@media (min-width: 1200px) {
  .testimonials-one .container {
    max-width: 1220px;
  }
}

.testimonials-one-card {
  border: 1px solid var(--cretech-border, #e1dbec);
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.07);
  padding: 40px;
  position: relative;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.07);
  background-color: #fff;
}

.testimonials-one-card:hover {
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.1), inset 0 0 0 5px var(--cretech-secondary, #9c2ede);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.1), inset 0 0 0 5px var(--cretech-secondary, #9c2ede);
  border-color: transparent;
}

@media (min-width: 768px) {
  .testimonials-one-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .testimonials-one-card {
    padding-left: 0px;
    margin-left: 40px;
    padding-right: 47px;
  }
}

.testimonials-one-card__image {
  display: inline-block;
  margin-bottom: 30px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .testimonials-one-card__image {
    margin-bottom: 0;
    margin-right: 38px;
  }
}

@media (min-width: 1200px) {
  .testimonials-one-card__image {
    margin-left: -40px;
  }
}

.testimonials-one-card__image img {
  border: 5px solid var(--cretech-secondary, #9c2ede);
}

@media (min-width: 768px) {
  .testimonials-one-card__text {
    margin-top: -8px;
  }
}

.testimonials-one-card__title {
  font-size: 20px;
  line-height: 1.1;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: var(--cretech-black, #12062c);
  margin: 0;
  margin-bottom: 7px;
  margin-top: 15px;
}

.testimonials-one-card__designation {
  text-transform: uppercase;
  color: var(--cretech-primary, #6a2dec);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: .1em;
  margin: 0;
  line-height: 1;
}

.testimonials-one-card__icon {
  position: absolute;
  bottom: 30px;
  right: 40px;
  font-size: 45px;
  background-image: linear-gradient(55deg, var(--cretech-secondary, #9c2ede) 0%, var(--cretech-primary, #6a2dec) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 1200px) {
  .testimonials-one-card__icon {
    font-size: 64px;
    right: 60px;
  }
}

.testimonials-two .section-title {
  padding-bottom: 25px;
}

.testimonials-two__content__text {
  margin-bottom: 30px;
}

.testimonials-two__content__btn {
  margin-top: 10px;
}

@media (min-width: 1200px) {
  .testimonials-two__items {
    padding-left: 89px;
  }
}


.campaign-card-title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 20px;
}

.one-line-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-campaign-dropdown {
  max-width: 240px;
}

.not-found-page-container {
  margin-top: 175px !important;
}

.text-yellow {
  color: #D4AF37;
}

.mobile-navbar-div-item {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: var(--cretech-link-light, #877e9b);
  display: block;
  line-height: 40px;
}

.mobile-navbar-register {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: var(--cretech-link-light, #877e9b);
  display: block;
  line-height: 40px;
}

@media screen and (max-width: 1800px) {
  .navbar-menu-hover {
    background-color: rgb(18, 6, 44);
    border-radius: 3px;
  }

  .navbar-menu-hover .main-menu__cta__text b {
    padding: 14px 10px;
  }

  .mobile-navbar-animate-item a {
    color: #797582 !important;
  }

  .mobile-navbar-animate-item:hover {
    background-color: #D4AF37;
  }

  .mobile-navbar-animate-item:hover a {
    color: #fff !important;
  }

  .motion-user-custom {
    position: absolute;
    right: 12%;
  }
}

@media screen and (max-width: 834px) {
  .not-found-page-container {
    margin-top: 100px !important;
  }

  .not-found-page-text {
    text-align: center;
  }

  .project-card-one__content__inner {
    transform: scale(1, 1);
    visibility: visible;
    transform-origin: top center;
  }
}