.page-header {
	background-color: $cretech-black;
	position: relative;
	padding-top: 70px;
	padding-bottom: 70px;

	@media (min-width: 768px) {
		padding-top: 150px;
		padding-bottom: 150px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/shapes/page-header-1-1.png);
		background-repeat: no-repeat;
		background-position: top right;
		z-index: 1;
	}

	&__bg {
		position: absolute;
		background-repeat: no-repeat;
		background-size: cover;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $cretech-black;
		mix-blend-mode: luminosity;
		opacity: .7;
	}

	.container {
		position: relative;
		z-index: 3;
	}

	.thm-breadcrumb {
		display: flex;
		flex-wrap: wrap;
		@include reset-ul;

		li {
			text-transform: uppercase;
			color: #fff;
			font-weight: bold;
			font-size: 14px;
			letter-spacing: .1em;
			display: flex;
			align-items: center;


			&:not(:last-child) {
				&::after {
					content: '/';
					margin-left: 10px;
					margin-right: 10px;
				}
			}

			a {
				background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 77% / 0px 1px no-repeat;
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					background-size: 100% 1px;
				}
			}
		}
	}

	&__title {
		margin: 0;
		color: #fff;
		letter-spacing: -.05em;
		font-size: 38px;
		margin-top: 2px;
		font-weight: bold;

		@media (min-width: 768px) {
			font-size: 42px;
		}

		@media (min-width: 992px) {
			font-size: 48px;
		}
	}
}